import Vue from "vue";
import Vuex from "vuex";
//import SocketsListener from "./sockets.js";
import _ from "lodash";
import axios from "axios";
import router from "./router";
// import config from "../config";
// import moment from "moment";

import TvPages from "../views/TvPages.js";
import TvImages from "../views/TvImages.js";

Vue.use(Vuex);
//SocketsListener.init(Vue.prototype.$socket, Vue.prototype);
let accesstoken = window.localStorage.getItem("accesstoken") ? window.localStorage.getItem("accesstoken") : "";
let refreshtoken = window.localStorage.getItem("refreshtoken") ? window.localStorage.getItem("refreshtoken") : "";

let defaultUser = {
  us_id: "",
  us_name: "",
  us_firstname: "",
  us_function: "",
  us_mobile1: "",
  us_tel1: "",
  us_email1: "",
  us_type: ""
};
let user = _.cloneDeep(defaultUser);

// let items_rights_roles = [];
// let items_rights_group = [];
// let items_rights_ressources = [];
// let items_civility = [
//   {
//     value: "mme",
//     text: "Madame"
//   },
//   {
//     value: "m",
//     text: "Monsieur"
//   }
// ];

export default new Vuex.Store({
  state: {
    // items_civility: items_civility,
    // items_rights_roles: items_rights_roles,
    // items_rights_ressources: items_rights_ressources,
    // items_rights_group: items_rights_group,
    // items_fov_label_size: [
    //   { value: "h1", text: "Titre" },
    //   { value: "h2", text: "Sous-titre" },
    //   { value: "h3", text: "Sous-sous-titre" }
    // ],
    // challenge_for_webapp: "",
    mainloading: false,
    currentcontact: null,
    // lastcoid: "",
    mysocket: null,
    mysocket_connected: false,
    connected: false,
    box_connected: false,
    accesstoken,
    refreshtoken,
    user,
    preferences: {
      personal: {},
      shared: {},
      rights: {}
    },
    reload_actions: 0,
    inputOpened: null,
    dialogErr: false,
    dialogErrTxt: "",
    pairedButton: {
      batterylevel: 0, // %
      appaired: false,
      light: false,
      bluetoothmacaddress: "", // uuid
      error: ""
    },
    resolutions: {
      availables: [
        { key: 1, value: "1200x600 interlaced" },
        { key: 2, value: "2300x1350 interlaced" }
      ],
      current: {
        key: 0,
        value: ""
      }
    },
    boxstates: {
      current_part: "",
      camera: true,
      leds: true,
      box_connected: true,
      button: true,
      button_level_battery: 0,
      button_paired: false
    },

    // sources: {},
    // network: null,
    // camera: null,
    // infos: null,
    lang: "french",
    start_config: false,
    socket_response: null,
    salt_md5: "MK&1#Z48WOSX72S"
  },
  mutations: {
    // SOCKET_connect(state) {
    //   console.warn("SOCKET CONNECT MUTATION");
    // },

    // SOCKET_disconnect(state) {
    //   console.warn("SOCKET DISCONNECT MUTATION");
    //   state.mysocket_connected = false;
    //   state.mysocket = null;
    //   state.mysocket_connected_to_room = false;
    // },

    set_inputOpened(state, input) {
      if (input) {
        if (state.inputOpened && input.name !== state.inputOpened.name) {
          state.inputOpened.hideDropdown();
        }
        state.inputOpened = input;
      } else {
        state.inputOpened = null;
      }
    },
    set_box_connected(state, val) {
      state.box_connected = val;
    },
    set_boxstates(state, _boxstates) {
      state.boxstates = _boxstates;
    },
    set_currentcontact(state, val) {
      state.currentcontact = val;
    },
    set_mainloading(state, ok) {
      state.mainloading = ok;
    },
    // set_lastcoid(state, us_id) {
    //   state.lastcoid = us_id;
    // },
    set_mysocket(state, socketData) {
      if (socketData.hasOwnProperty("socket")) state.mysocket = socketData.socket;
      state.mysocket_connected = socketData.state;
      state.mysocket_connected_to_room = socketData.room;
    },
    set_reload_actions(state) {
      state.reload_actions = Math.random();
    },
    // utiliser simplement pour mettre à jour les infos essentielles
    // set_user_simple(state, user) {
    //   state.user.us_name = user.us_name;
    //   state.user.us_firstname = user.us_firstname;
    //   state.user.us_type = user.us_type;
    //   state.user.updatedAt = user.updatedAt;
    // },
    set_tokens(state, what) {
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      // window.localStorage.setItem("refreshtoken", what.refreshtoken);
      // state.refreshtoken = what.refreshtoken;
    },
    set_connexion(state, what) {
      //console.log("what", what);
      if (!what.accesstoken) {
        axios.defaults.headers.common["x-auth-accesstoken"] = "";
        window.localStorage.removeItem("accesstoken");
        state.accesstoken = null;
        window.localStorage.removeItem("refreshtoken");
        state.refreshtoken = null;
        window.localStorage.removeItem("user");
        state.user = _.cloneDeep(defaultUser);
        state.connected = false;
        state.items_rights_group = [];
        state.box_connected = false;
        return;
      }
      // console.log("token, refreshtoken, user", what.accesstoken, what.refreshtoken, what.user);
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      window.localStorage.setItem("refreshtoken", what.refreshtoken);
      state.refreshtoken = what.refreshtoken;
      window.localStorage.setItem("user", JSON.stringify(what.user));
      state.user = _.cloneDeep(what.user);
      state.preferences = _.cloneDeep(what.preferences);
      if (what.more && what.more.box_connected) state.box_connected = true;
      else state.box_connected = false;
      // if (what.more) state.challenge_for_webapp = what.more.challenge_for_webapp;
      // state.items_rights_ressources = what.preferences.rights.acl_resources;
      // state.items_rights_roles = what.preferences.rights.acl_roles;
      // state.items_rights_group = what.preferences.rights.acl_groups;
      //console.log("what.preferences", what.preferences);
      _.each(what.preferences.items, (val, key) => {
        state[key] = what.preferences.items[key];
      });

      state.connected = true;
      // this.dispatch("load_nbclientsuivi");
      this.dispatch("socketjoinroom");
    },
    // set_acl_groups(state, what) {
    //   state.items_rights_group = what;
    // },
    // set_preferences(state, data) {
    //   state.preferences[data.preference] = data.data;
    // },
    set_dialog_error(state, what) {
      // console.log("what", what);
      state.dialogErr = what.dialogErr;
      state.dialogErrTxt = what.dialogErrTxt;
    },
    // set_paired_button(state, val) {
    //   state.pairedButton = val;
    // },
    // set_testing_button(state, val) {
    //   state.pairedButton.testing = val;
    // },
    // set_resolutions(state, val) {
    //   if (val.hasOwnProperty("current") && val.current) state.resolutions.current = val.current;
    //   if (val.hasOwnProperty("availables")) state.resolutions.availables = val.availables;
    // },
    // set_sources(state, val) {
    //   state.sources = val;
    // },
    // set_network_info(state, val) {
    //   state.network = val;
    // },
    // set_camera(state, val) {
    //   state.camera = val;
    // },
    // set_infos(state, val) {
    //   state.infos = val;
    // },
    set_error_login(state, val) {
      state.errorLogin = val;
    },
    set_lang(state, val) {
      state.lang = val;
    },
    set_start_config(state, val) {
      state.start_config = val;
      if (!val) {
        router.push("/home").catch(err => {});
      }
    },
    set_socket_response(state, response) {
      state.socket_response = response;
    },
    set_box_challenge(state, val) {
      state.user.en_id.bo_challenge = val;
    }
  },
  actions: {
    initSocketEvents(ctx) {
      let socket = this._vm.$SocketIO;
      socket.on("connect", socket => {
        console.warn("SOCKET CONNECTED ACTION", socket);
        ctx.commit("set_mysocket", { socket: this._vm.$SocketIO, state: true, room: false });
        this.dispatch("socketjoinroom");
      });
      socket.on("/config/2.0/show/ok", response => {
        if (ctx.state.accesstoken !== response.accesstoken) return console.warn("/config/2.0/show/ok > challenge error");
        console.warn("SOCKET SHOW OK", response.cmd, response.data);
        if (response.cmd == "current-part") {
          if (response.data == "config") ctx.commit("set_start_config", true);
          else ctx.commit("set_start_config", false);
        }
        ctx.commit("set_socket_response", response);
        // this.dispatch("getDataOk", { cmd: response.cmd, data: response.data });
      });
      socket.on("/config/2.0/do/ok", response => {
        if (ctx.state.accesstoken !== response.accesstoken) return console.warn("/config/2.0/do/ok > challenge error");
        console.warn("SOCKET DO OK", response.cmd, response.data);

        ctx.commit("set_socket_response", response);
        // this.dispatch("setDataOk", { cmd: response.cmd, data: response.data });
      });
      socket.on("/config/2.0/update-box-state", response => {
        if (ctx.state.accesstoken !== response.accesstoken) return console.warn("/config/2.0/update-box-state > challenge error");
        console.warn("SOCKET update-box-state", response);
        // get the new bo_challenge
        ctx.commit("set_box_connected", response.box_connected);
        ctx.commit("set_boxstates", response);
        // if (response.box_connected) ctx.commit("set_box_challenge", response.bo_challenge);
      });

      socket.on("disconnect", () => {
        ctx.commit("set_mysocket", { socket: null, state: false, room: false });
      });
    },
    getBoxState(ctx) {
      // /webapp/2.0/update-
      // accesstoken: ctx.state.accesstoken
      // console.log("getBoxState");
      ctx.state.mysocket.emit("/config/2.0/get-box-state", {
        accesstoken: ctx.state.accesstoken
      });
    },
    showDialogError(ctx, err) {
      ctx.commit("set_dialog_error", {
        dialogErr: err ? true : false,
        dialogErrTxt: err ? err : ""
      });
    },
    socketjoinroom(ctx, socket) {
      if (ctx.state.mysocket_connected && ctx.state.connected && ctx.state.user.bo_id) {
        console.warn("JOINING SOCKET ROOM");
        //let timestamp = Date.now();
        ctx.state.mysocket.emit(
          "/config/2.0/join",
          {
            accesstoken: ctx.state.accesstoken,
            //timestamp,
            bo_mac_address: ctx.state.user.bo_id.bo_mac_address
          },
          err => {
            if (err) {
              alert("Une erreur est survenue, nous allons recharger l'application");
              document.location.reload();
              return console.warn("SOCKET ROOM NOT JOINED");
            }
            console.warn("SOCKET ROOM JOINED");
            ctx.state.mysocket_connected_to_room = true;
          }
        );
      }
    },
    async getData(ctx, key) {
      if (!ctx.state.box_connected) return;
      console.warn("getData > ", { bo_mac_address: ctx.state.user.bo_id.bo_mac_address, cmd: key });
      // let timestamp = Date.now();
      ctx.state.mysocket.emit("/config/2.0/show", {
        accesstoken: ctx.state.accesstoken,
        // timestamp,
        bo_mac_address: ctx.state.user.bo_id.bo_mac_address,
        cmd: key
      });
    },
    async setData(ctx, data) {
      if (!ctx.state.box_connected) return;
      console.warn("set data > ", data);
      // let timestamp = Date.now();
      ctx.state.mysocket.emit("/config/2.0/do", {
        accesstoken: ctx.state.accesstoken,
        // timestamp,
        bo_mac_address: ctx.state.user.bo_id.bo_mac_address,
        ...data
      });
    },
    getTvBrands(ctx) {
      return new Promise(resolve => {
        if (!ctx.state.box_connected) return resolve();
        console.warn("getData > ", { bo_mac_address: ctx.state.user.bo_id.bo_mac_address });
        // let timestamp = Date.now();
        ctx.state.mysocket.emit(
          "/config/2.0/tv-brands",
          {
            accesstoken: ctx.state.accesstoken,
            // timestamp,
            bo_mac_address: ctx.state.user.bo_id.bo_mac_address
          },
          (tvbrands, tvbrandsfiles, macros) => {
            let tvbrandsdata = { tvbrands, tvbrandsfiles, macros };
            resolve(tvbrandsdata);
          }
        );
      });
    },
    // async getDataOk(ctx, response) {
    //   let key = response.cmd,
    //     data = response.data;
    //   console.warn("getDataOK", key, data);
    //   if (key.indexOf("button") == 0) {
    //     // if (key == "button") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   if (data.appaired) this.dispatch("sendTvPages", { key, html: TvPages.html_button, state: "BTN_STATUS=success", replace: "" });
    //     //   else this.dispatch("sendTvPages", { key, html: TvPages.html_button, state: "BTN_STATUS=unappair", replace: "" });
    //     // }
    //     // if (key == "button-appair") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=appair", replace: "" });
    //     // }
    //     // if (key == "button-success") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=success", replace: "" });
    //     // }
    //     // if (key == "button-unappair") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=unappair", replace: "" });
    //     // }
    //     // if (key == "button-light") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=light", replace: "" });
    //     // }
    //     // } else if (key == "resolution") {
    //     //   ctx.commit("set_resolutions", data);
    //     //   this.dispatch("sendTvPages", {
    //     //     key,
    //     //     html: "html_resolution",
    //     //     state: "CURRENT_RESOLUTION",
    //     //     replace: ctx.getters.get_currentResolution
    //     //   });
    //   } else if (key.indexOf("resolution-test") == 0) ctx.commit("set_resolutions", data);
    //   else if (key == "sources") {
    //     ctx.commit("set_sources", data);
    //     let replaces = {};
    //     let states = Object.keys(data).map(datakey => {
    //       replaces[datakey.toUpperCase()] = data[datakey];
    //       return datakey.toUpperCase();
    //     });
    //     console.warn("getDataOK > sources > ", states, replaces);
    //     this.dispatch("sendTvPages", { key, html: "html_sources", state: states, replace: replaces });
    //   }
    //   //else if (key.indexOf("sources/test")) ctx.commit("set_sources", response.data.data);
    //   // else if (key == "camera") ctx.commit("set_camera", data);
    //   // else if (key == "network") ctx.commit("set_network_info", data);
    //   // else if (key == "informations") ctx.commit("set_infos", data);

    //   return;
    // },
    // setDataOk(ctx, response) {
    //   let key = response.cmd;
    //   let data = response.data;
    //   console.warn("setDataOK", key, data);

    //   if (key.indexOf("button") == 0) {
    //     // if (key == "button") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   if (data.appaired) this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=success", replace: "" });
    //     //   else this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=unappair", replace: "" });
    //     // }
    //     // if (key == "button-appair") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=appair", replace: "" });
    //     // }
    //     // if (key == "button-success") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=success", replace: "" });
    //     // }
    //     // if (key == "button-unappair") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=unappair", replace: "" });
    //     // }
    //     // if (key == "button-light") {
    //     //   ctx.commit("set_paired_button", data);
    //     //   this.dispatch("sendTvPages", { key, html: "html_button", state: "BTN_STATUS=light", replace: "" });
    //     // }
    //   }
    //   if (key == "resolution") {
    //     ctx.commit("set_resolutions", data);
    //     this.dispatch("sendTvPages", {
    //       key,
    //       html: "html_resolution",
    //       state: "CURRENT_RESOLUTION",
    //       replace: ctx.getters.get_currentResolution
    //     });
    //   }
    //   if (key == "sources") {
    //     ctx.commit("set_sources", data);
    //     let replaces = {};
    //     let states = Object.keys(data).map(datakey => {
    //       replaces[datakey.toUpperCase()] = data[datakey];
    //       return datakey.toUpperCase();
    //     });
    //     this.dispatch("sendTvPages", { key, html: "html_sources", state: states, replace: replaces });
    //   }
    //   // if (key === "start-config") {
    //   //   // console.log("data", data);
    //   //   if (!data) {
    //   //     return alert("Il s'est produit une erreur. Merci de recommencer.");
    //   //   }
    //   //   this.commit("set_start_config", true);
    //   //   this.dispatch("sendTvPages", { key: "home", html: "", state: "", replace: "" });
    //   //   // this.dispatch("getData", "informations");
    //   // }
    //   // if (key === "start-animation") {
    //   //   this.commit("set_start_config", false);
    //   // }
    // },
    sendInfosTvPages(ctx) {
      // let replaces = {};
      // get camera state name
      // let states_camera = Object.keys(ctx.state.camera).map(datakey => {
      //   replaces["CAMERA_" + datakey.toUpperCase()] = ctx.state.camera[datakey];
      //   return "CAMERA_" + datakey.toUpperCase();
      // });
      // get network states names with deep checking
      // let deep_states_network = [];
      // let states_network = Object.keys(ctx.state.network).map(datakey => {
      //   let prefix = "";
      //   let deep_states = null;
      //   if (ctx.state.network[datakey] && ctx.state.network[datakey].constructor.name === "Object") {
      //     // download or upload deep object { bandwidth : "", status : "" } -> will be : NETWORK_DOWNLOAD_BANDWIDTH ...
      //     prefix = "NETWORK_" + datakey.toUpperCase() + "_";
      //     // deep_states is an array with keys of sub object download or upload and PREFIX -> [ "<PREFIX>BANDWIDTH", "<PREFIX>STATUS" ]
      //     deep_states = Object.keys(ctx.state.network[datakey]).map(deep_state => {
      //       replaces[prefix + deep_state.toUpperCase()] = ctx.state.network[datakey][deep_state];
      //       return prefix + deep_state.toUpperCase();
      //     });
      //   } else replaces["NETWORK_" + datakey.toUpperCase()] = ctx.state.network[datakey];
      //   if (deep_states) deep_states_network.push(...deep_states);
      //   return deep_states ? null : "NETWORK_" + datakey.toUpperCase();
      // });
      // states_network.push(...deep_states_network);
      // states_network = states_network.filter(v => v);
      // get infos states name
      // let states_infos = Object.keys(ctx.state.infos).map(datakey => {
      //   replaces["INFOS_" + datakey.toUpperCase()] = ctx.state.infos[datakey];
      //   return "INFOS_" + datakey.toUpperCase();
      // });
      // let states = [...states_camera, ...states_network, ...states_infos];
      // this.dispatch("sendTvPages", { key: "informations", html: "html_informations", state: states, replace: replaces });
    },

    async sendTvPages(ctx, data) {
      // console.log("data", data);
      let html = TvPages[data.html];
      let style = "";
      if (data.key === "sources" || data.key === "informations" || data.key === "region") {
        Object.keys(data.state).forEach(state => {
          if (data.state[state] && data.state[state].constructor.name === "Object") {
            Object.keys(data.state[state]).forEach(deepState => {
              html = html
                .replace("<!--" + state.toUpperCase() + "_" + deepState.toUpperCase(), data.state[state][deepState])
                .replace(state.toUpperCase() + "_" + deepState.toUpperCase() + "-->", "");
            });
          }
          html = html.replace("<!--" + state.toUpperCase(), data.state[state]).replace(state.toUpperCase() + "-->", "");
        });
        // html = data.html;
      } else {
        if (html) html = html.replace("<!--" + data.state, data.replace).replace(data.state + "-->", "");
        else html = TvPages.html_home;
      }
      style = TvPages.html_styles.replace(`$BASE64_${data.key.toUpperCase()}_IMAGE`, TvImages[`image_${data.key}`]);
      html += `<style>${style}</style>`;
      // console.log("html", html);
      if (ctx.state.box_connected) {
        // console.log(html);
        // let timestamp = Date.now();
        ctx.state.mysocket.emit("/config/2.0/tv-page", {
          accesstoken: ctx.state.accesstoken,
          // timestamp,
          bo_mac_address: ctx.state.user.bo_id.bo_mac_address,
          html
        });
      }
    }
    // startConfig(ctx) {
    //   if (!ctx.state.box_connected) return;
    //   ctx.state.mysocket.emit("/config/2.0/do", { bo_mac_address: ctx.state.user.bo_id.bo_mac_address, cmd: "start-config" });
    // },
    // startAnimation(ctx) {
    //   if (!ctx.state.box_connected) return;
    //   ctx.state.mysocket.emit("/config/2.0/do", { bo_mac_address: ctx.state.user.bo_id.bo_mac_address, cmd: "start-animation" });
    // }
  },
  modules: {},
  getters: {
    get_inputOpened(state) {
      return state.inputOpened;
    }
    // get_currentResolution(state) {
    //   return state.resolutions.current.value;
    // }
  }
});
