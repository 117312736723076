<template>
  <section>
    <div class="container mt-5" id="puckbutton">
      <div class="row align-items-center">
        <div class="col" align="center">
          <div class="d-flex flex-row justify-content-center">
            <h1 class="mb-2 mr-3">Configuration du bouton</h1>
            <!-- <div class="img-titre-resolution"></div> -->
            <div><icon name="circle" color="#91295a" scale="3"></icon></div>
          </div>
          <div ref="buttonState" class="m-4 buttonState d-flex justify-content-center align-items-center">
            <p class="m-0" v-if="batterylevel > 0">{{ batterylevel }}%</p>
          </div>

          <div v-if="buttonStatus == 'appairing'">
            <div class="alert alert-info">
              <b>Votre bouton va s'allumer en rouge dans environ 10 secondes, <b>appuyez sur le bouton pour confirmer l'association</b>. </b>
              <br /><br />
              Si ce n'est pas le cas, veuillez procéder à sa réinitialisation, en enlevant la pile et en la remettant tout en laissant appuyer sur le
              bouton jusqu'a ce que les leds ne clignottent plus, enfin cliquez sur le bouton RECHERCHER LE BOUTON.
            </div>
          </div>
          <div v-if="light">
            <div class="alert alert-info">Le bouton va s'allumer en vert.</div>
          </div>

          <div v-if="batterylevel && batterylevel < 25">
            <div class="alert alert-warning">
              <b>Le niveau de charge de la pile de votre bouton est faible, <b>Veuillez la remplacer rapidement</b>. </b>
              <br />
            </div>
          </div>

          <div v-if="buttonStatus == 'appaired' || buttonStatus == 'notfound'">
            <div class="alert alert-info">
              <div v-if="buttonStatus === 'appaired' && buttonClick && buttonClick < 10">
                Vous venez d'appuyer {{ buttonClick }} fois sur le bouton
              </div>
              <div v-if="buttonStatus === 'appaired' && buttonClick && buttonClick >= 10">
                Vous venez d'activer le mode configuration sur le bouton
              </div>
              <div class="">Un bouton est associé à votre kit Emotivi.</div>
              <div class="row">
                <div class="col d-flex flex-column">
                  <!-- <button class="btn btn-primary uppercase" disabled @click="testing()">
                  Allumer le bouton associé
                </button> -->
                  <button class="btn btn-danger uppercase mt-3" @click="unappair()">Supprimer le bouton associé</button>
                  <small class="form-text text-muted">Cette action vous permet d'associer ensuite à votre kit Emotivi un nouveau bouton.</small>
                </div>
              </div>
            </div>
          </div>
          <div v-if="buttonStatus == 'notfound'">
            <div class="alert alert-warning">
              <div class="">
                Un bouton est associé à votre kit Emotivi, mais n'a pas été trouvé. La pile est peut être déchargée, le bouton est hors de portée ou
                le processus d'association ne s'est pas terminé correctement.
              </div>
            </div>
          </div>
          <div v-if="buttonStatus == 'notappaired'">
            <div class="alert alert-info">
              <div class="">Aucun bouton n'est associé à votre kit Emotivi.</div>
              <div v-if="scannedButtons.length === 0 && displayScan" class="alert alert-danger mt-1">
                Aucun bouton n'a été trouvé. Démontez le bouton afin de vérifier que la pile est bien inséré, puis recliquez sur "Rechercher le
                bouton"
              </div>
              <div v-if="scannedButtons.length === 1 && scannedButton && scannedButton.address && buttonConfigured" class="alert alert-danger mt-1">
                Un bouton déjà associé à un kit Emotivi a été scanné. Pour relancer l'association du bouton, réinitialisez le en retirant la pile et
                en la réinsérant tout en appuyant sur le bouton et en laissant appuyé jusqu'a ce que les leds ne s'allument plus. Enfin, appuyez sur
                Rechercher le bouton.
              </div>
              <div v-else-if="scannedButtons.length === 1 && scannedButton && scannedButton.address && !buttonConfigured" class="mt-1">
                <b>Un bouton a été scanné. Cliquez sur 'ASSOCIER LE BOUTON'.</b>
              </div>

              <button
                v-if="scannedButtons.length === 1 && scannedButton && scannedButton.address"
                :disabled="buttonConfigured"
                class="btn btn-primary uppercase w-100 mt-2"
                @click="appair(scannedButton.address)"
              >
                Associer le bouton
              </button>
            </div>
          </div>
          <div v-if="buttonStatus !== 'appaired' && buttonStatus !== 'notfound'" class="mt-3">
            <p v-if="scanLoading">Recherche du bouton en cours...</p>
            <icon v-if="scanLoading" class="mr-2 mb-2 fa-spin" name="sync-alt" color="black" scale="3"></icon>

            <button :disabled="!showScanBt" class="btn btn-primary uppercase w-100" @click="scanButtons()">Rechercher le bouton</button>
          </div>
          <div v-if="scannedButtons.length > 1" class="row mt-4 justify-content-center">
            <div id="scannedButtons" v-for="button in scannedButtons" :key="button.address" class="w-75">
              <div class="row align-items-center justify-content-start w-100 p-1 position-relative">
                <img style="width: 15px" class="img-fluid mr-3" src="/images/button.png" />
                <!-- <span class="text-primary mr-3"> {{ button.localName ? button.localName : "adresse" }} : {{ button.address }}</span> -->
                <span class="text-secondary mr-3" v-show="button.rssi">signal : {{ button.rssi }} db</span>
                <span class="text-info mr-3" v-if="button.script">Associé à un autre kit Emotivi</span>
                <span class="text-info mr-3" v-else>Non configuré</span>

                <span
                  class="text-light font-weight-bold flex-grow-1 d-flex align-items-center justify-content-center"
                  style="background-color: #91295a"
                  v-if="buttonStatus === 'appaired' && button.address === buttonMacAddress"
                >
                  Associé
                  <!-- <button class="btn btn-secondary ml-5" @click="testing(button.address)">Allumer</button> -->
                </span>
                <div class="d-flex justify-content-end h-100" style="right: 0">
                  <button
                    v-if="buttonStatus !== 'appaired' && !button.script"
                    class="btn btn-primary border d-flex align-items-center justify-content-center"
                    @click="appair(button.address)"
                  >
                    Associer
                  </button>
                  <button
                    v-if="buttonStatus !== 'appaired' && !button.script"
                    class="btn btn-warning border d-flex align-items-center justify-content-center"
                    @click="testing(button.address)"
                  >
                    <span v-if="buttonsGreen.indexOf(button.address) >= 0">Eteindre</span>
                    <span v-else>Allumer</span>
                  </button>
                </div>
              </div>
            </div>
            <p class="p-3" v-if="displayScan && buttonStatus === 'appaired' && buttonMacAddress && !pairedButtonScanned">
              Le bouton associé à votre kit Emotivi n'a pas pu être scanné, il se peut que la pile soit déchargée ou que le bouton soit trop éloigné
              du kit.
            </p>
            <p class="p-3" v-if="displayScan && buttonConfigured && buttonStatus === 'notappaired'">
              Aucun bouton n'est associé à votre kit Emotivi mais un bouton déjà configuré a été scanné. Pour relancer l'association du bouton,
              réinitialisez le en retirant la pile et en la réinsérant tout en appuyant sur le bouton et en laissant appuyé jusqu'a ce que les leds ne
              s'allument plus. Enfin, appuyez sur associer un bouton.
            </p>
          </div>

          <div class="row mt-4">
            <div class="col-6 d-flex flex-row justify-content-left">
              <button class="btn btn-warning" @click="previousStep()"><icon class="mr-2" name="arrow-left"></icon> Retour</button>
            </div>
            <div class="col-6 d-flex flex-row justify-content-end">
              <button class="btn btn-warning" @click="nextStep()">Suivant <icon class="ml-2" name="arrow-right"></icon></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "puckbutton",
  components: {},
  async mounted() {
    window.scrollTo(0, 0);

    this.$store.commit("set_mainloading", true);
    this.$store.dispatch("getData", "button");
    this.scanButtons();
    // this.$store.state.pairedButton.appaired ? (this.buttonStatus = "appaired") : (this.buttonStatus = "notappaired");
    // this.drawState();
  },
  data() {
    return {
      batterylevel: 0,
      buttonStatus: "none",
      light: false,
      scannedButtons: [],
      pairedButtonScanned: false,
      buttonConfigured: false,
      displayScan: false,
      scanLoading: false,
      buttonMacAddress: "",
      // displayTable: false,
      scannedButton: null,
      buttonsGreen: [],
      showScanBt: true,
      buttonClick: 0
    };
  },
  watch: {
    socket_response(response) {
      console.warn("SOCKET SHOW OK 2", response.cmd, response.data);

      if (response.cmd == "button") {
        this.showScanBt = true;
        this.batterylevel = response.data.batterylevel * 1;
        this.buttonMacAddress = response.data.bluetoothmacaddress;
        if (response.data.status == "yes") {
          this.buttonStatus = "appaired";

          if (this.batterylevel < 25)
            this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=lowbattery", replace: "" });
          else this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=success", replace: "" });
        } else {
          if (this.buttonMacAddress) {
            // appaired but not found
            this.buttonStatus = "notfound";
            this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=notfound", replace: "" });
          } else {
            this.buttonStatus = "notappaired";
            this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=unappair", replace: "" });
          }
        }

        this.$store.commit("set_mainloading", false);
      }
      if (response.cmd == "button-appair") {
        this.$store.dispatch("sendTvPages", { key: "button-appair", html: "html_button", state: "BTN_STATUS=appair", replace: "" });
        // this.$store.dispatch("getData", "button");
      }

      if (response.cmd == "button-unappair") {
        this.$store.dispatch("sendTvPages", { key: "button-unappair", html: "html_button", state: "BTN_STATUS=unappair", replace: "" });
      }
      if (response.cmd == "button-scan") {
        this.scanLoading = false;
        if (response.data) {
          this.displayScan = true;

          let scannedButtons = [];
          this.$_.each(response.data, (value, key) => {
            scannedButtons.push(value);
          });
          scannedButtons.sort((a, b) => {
            return b.rssi * 1 - a.rssi * 1;
          });

          // test un bouton scanné
          // scannedButtons = scannedButtons.filter(button => {
          //   if (button.address === "c8:a3:12:bc:6d:7b") return true;
          //   return false;
          // });

          //
          // console.log("RESPONSE : ", scannedButtons);
          this.scannedButtons = scannedButtons;
          this.pairedButtonScanned = false;
          this.buttonConfigured = false;
          this.scannedButton = null;
          this.scannedButtons.forEach(button => {
            if (this.buttonStatus === "appaired" && this.buttonMacAddress === button.address) this.pairedButtonScanned = true;
            if (this.buttonStatus === "notappaired" && button.script) this.buttonConfigured = true;
            this.scannedButton = button;
          });

          //if (this.scannedButtons.length > 1) this.displayTable = true;
        }

        // if (response.data) this.scannedButtons.dgfsdjbgfs = { address: "hgbdsfjjsbds", rssi: "-25", script: true };
        // if (response.data) this.scannedButtons.ghghghghgh = { address: "gghghghghghghghgh", rssi: "-25" };
      }
      if (response.cmd == "button-success") {
        if (response.data) {
          this.buttonStatus = "appaired";
          this.buttonMacAddress = response.data;
          this.$store.dispatch("sendTvPages", { key: "button-appair", html: "html_button", state: "BTN_STATUS=success", replace: "" });
          this.scanButtons();
        }
      }
      if (response.cmd == "button-unappair") {
        if (response.data) {
          this.buttonStatus = "notappaired";
          this.buttonMacAddress = "";
          this.$store.dispatch("sendTvPages", { key: "button-appair", html: "html_button", state: "BTN_STATUS=unappair", replace: "" });
          this.scanButtons();
        }
      }
      if (response.cmd === "button-click") {
        this.buttonClick = response.data.click;
      }
    },
    boxstates: {
      handler(val) {
        this.batterylevel = val.button_level_battery;

        if (val.button) {
          this.buttonStatus = "appaired";

          if (this.batterylevel < 25)
            this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=lowbattery", replace: "" });
          else this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=success", replace: "" });
        } else {
          if (val.button_paired) {
            // appaired but not found
            this.buttonStatus = "notfound";
            this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=notfound", replace: "" });
          } else {
            this.buttonStatus = "notappaired";
            this.$store.dispatch("sendTvPages", { key: "button", html: "html_button", state: "BTN_STATUS=unappair", replace: "" });
          }
        }
      },
      deep: true
    }
  },
  computed: {
    socket_response() {
      return this.$store.state.socket_response;
    },
    boxstates() {
      return this.$store.state.boxstates;
    }
  },

  methods: {
    async testing(buttonAddress) {
      //this.showScanBt = false;
      let on = "light";
      if (this.buttonsGreen.indexOf(buttonAddress) >= 0) {
        on = "unlight";
        this.buttonsGreen.splice(this.buttonsGreen.indexOf(buttonAddress), 1);
      } else {
        this.buttonsGreen.push(buttonAddress);
      }
      this.$store.dispatch("setData", { cmd: "button-" + on, data: buttonAddress });
    },
    async appair(buttonAddress) {
      this.buttonStatus = "appairing";
      //this.showScanBt = false;
      this.drawState();
      await this.$store.dispatch("setData", { cmd: "button-appair", data: buttonAddress });
    },
    async unappair() {
      if (window.confirm("Etes-vous certain de vouloir supprimer l'association du bouton ?"))
        await this.$store.dispatch("setData", { cmd: "button-unappair" });
    },
    drawState() {
      let el = this.$refs.buttonState;
      if (this.buttonStatus == "appaired") {
        el.style.backgroundColor = "green";
      } else if (this.buttonStatus == "appairing") {
        el.style.backgroundColor = "orange";
      } else {
        el.style.backgroundColor = "red";
      }
    },
    previousStep() {
      this.$router.push("/region");
    },
    async nextStep() {
      let ok = true;
      if (this.buttonStatus !== "appaired") {
        ok = window.confirm("Vous n'avez pas associé de bouton au kit Emotivi. Souhaitez-vous continuer ?");
      }
      if (ok) this.$router.push("/sources");
    },
    async scanButtons() {
      //this.showScanBt = false;
      this.scannedButtons = [];
      this.scanLoading = true;
      this.displayScan = false;
      this.buttonStatus = this.buttonStatus === "appairing" ? "notappaired" : this.buttonStatus;
      await this.$store.dispatch("setData", { cmd: "button-scan" });
    }
  }
  // computed: {
  //   // pairedButton() {
  //   //   return this.$store.state.pairedButton;
  //   // }
  // },
  // watch: {
  //   // pairedButton: {
  //   //   deep: true,
  //   //   handler(value) {
  //   //     value.appaired ? (this.buttonStatus = "appaired") : (this.buttonStatus = "notappaired");
  //   //     this.light = value.light;
  //   //     this.drawState();
  //   //   }
  //   // }
  // }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.buttonState {
  background-color: grey;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
#scannedButtons:nth-child(even) {
  background: rgb(230, 230, 230);
}

#scannedButtons:nth-child(odd) {
  background: rgb(236, 247, 255);
}
</style>
