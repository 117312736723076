import { render, staticRenderFns } from "./MMessageDialog.vue?vue&type=template&id=e4e2241c&"
import script from "./MMessageDialog.vue?vue&type=script&lang=js&"
export * from "./MMessageDialog.vue?vue&type=script&lang=js&"
import style0 from "./MMessageDialog.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/emotivi-v2-config/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e4e2241c')) {
      api.createRecord('e4e2241c', component.options)
    } else {
      api.reload('e4e2241c', component.options)
    }
    module.hot.accept("./MMessageDialog.vue?vue&type=template&id=e4e2241c&", function () {
      api.rerender('e4e2241c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MMessageDialog.vue"
export default component.exports