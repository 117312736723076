<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content" :style="'width:' + width">
        <div class="modal-header">
          <h3 class="pt-2 ml-3">{{ title }}</h3>
        </div>
        <div class="modal-body" v-html="text"></div>
        <div class="modal-footer">
          <button class="btn btn-mouveal" @click="deleteWin">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mconfirmdialog",
  props: ["value", "title", "text", "sousText", "width", "redirect"],
  data() {
    return {
      dialog: false
    };
  },
  mounted() {},
  watch: {
    value: function(val) {
      this.dialog = val;
      // if (!this.width) this.width = "500px";
    }
  },
  components: {},
  methods: {
    deleteWin() {
      this.dialog = false;
      if (this.redirect) {
        this.$router.push("/" + this.redirect);
      }
      this.$emit("input", false);
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss">
/************ style modal ***************/
.modal-content {
  border-radius: 0;
  margin: auto;
}
.modal-header {
  background-color: #013c62;
  color: #fff;
  border-radius: 0;
  padding: 20px 60px;
}
.modal-padding {
  padding: 40px 60px;
}

.btn-close {
  background-color: #fff;
  border-radius: 50%;
}
</style>
