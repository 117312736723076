import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store";
import axios from "axios";

//import Home from "../views/Home.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

import PuckButton from "../views/PuckButton.vue";
// import Resolution from "../views/Resolution.vue";
import Region from "../views/Region.vue";
import Sources from "../views/Sources.vue";
import Informations from "../views/Informations.vue";
//import TestLogin from "../views/TestLogin";

Vue.use(VueRouter);

function setLogout() {
  store.commit("set_connexion", {
    accesstoken: "",
    //refreshtoken: response.data.refreshtoken,
    user: {},
    more: {},
    preferences: {}
  });
}

const routes = [
  {
    path: "/",
    name: "redirectlogin",
    redirect: "/login"
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { menu: "home" }
  },

  {
    path: "/puckbutton",
    name: "puckbutton",
    component: PuckButton,
    meta: { menu: "puckbutton" }
  },
  // {
  //   path: "/resolution",
  //   name: "resolution",
  //   component: Resolution,
  //   meta: { menu: "resolution" }
  // },
  {
    path: "/region",
    name: "region",
    component: Region,
    meta: { menu: "region" }
  },
  {
    path: "/sources",
    name: "sources",
    component: Sources,
    meta: { menu: "sources" }
  },
  {
    path: "/informations",
    name: "informations",
    component: Informations,
    meta: { menu: "informations" }
  },
  // {
  //   path: "/testlogin",
  //   name: "testlogin",
  //   component: TestLogin,
  //   meta: { menu: "testlogin" }
  // },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { menu: "login" }
  },
  {
    path: "/logout",
    name: "logout",
    component: Login,
    meta: { menu: "login" }
  },
  {
    path: "/login/:code_admin",
    name: "login-autosignin",
    // component: Login,
    // props: true,
    meta: { menu: "login" }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  async function autolog() {
    if (!store.state.accesstoken) return false;
    // if (store.state.connected) return true;

    try {
      let response = await axios({
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL + "/config/2.0/autosignin"
      });
      if (response.data.err) {
        setLogout();
        return false;
      }
      let data = response.data.data;
      store.commit("set_connexion", {
        accesstoken: data.accesstoken,
        //refreshtoken: response.data.refreshtoken,
        user: data.row,
        more: data.more,
        preferences: data.preferences
      });
      // store.dispatch("startConfig");
      return true;
    } catch (error) {
      console.error("ko", error);
      return false;
    }
  }
  // console.log("to.name", to.name);
  if (to.name == "logout") {
    store.dispatch("setData", { cmd: "start-animation" });
    setLogout();
    return next();
  }
  if (to.name == "resetpass") return next();
  if (to.name == "login") return next();
  if (to.name == "login-autosignin") {
    store.commit("set_tokens", {
      accesstoken: to.params.code_admin
      // refreshtoken: response2.data.refreshtoken
    });
    let ok = await autolog();
    if (!ok) return next({ path: "/login" });
    store.dispatch("setData", { cmd: "start-config" });
    return next({ path: "/home" });
  }
  if (!store.state.connected) await autolog();

  // if (to.name == "home" && store.state.connected) return next();
  // if (to.name == "home" && !store.state.connected) return next({ path: "/login" });

  if (!store.state.connected) return next({ path: "/login" });

  if (
    (to.name === "sources" || to.name === "informations" || to.name === "resolutions" || to.name === "region" || to.name === "puckbutton") &&
    !store.state.start_config
  )
    return next({ path: "/home" });

  return next();
});

export default router;
