var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "m-form-checkbox" }, [
    _c("input", {
      attrs: { type: "checkbox", disabled: _vm.disabled, name: _vm.name2 },
      domProps: { checked: _vm.value },
      on: {
        input: function($event) {
          return _vm.setValue($event.target.checked)
        }
      }
    }),
    _vm._v(" " + _vm._s(_vm.label) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }