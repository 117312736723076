var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("header", { staticStyle: { height: "5vh" } }, [
        false
          ? _c(
              "div",
              {
                staticClass: "container-nav2",
                class: "bg-nav2-" + _vm.user.us_type
              },
              [
                _c("ul", { staticClass: "nav d-flex justify-content-center" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          class: {
                            "nav-link": true,
                            "link-accueil": true,
                            "link-active": _vm.linkActive === "home",
                            disabled: !_vm.$store.state.connected
                          },
                          staticStyle: { "border-left": "0 solid white" },
                          attrs: { to: "/home" }
                        },
                        [_vm._v("Accueil")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          class: {
                            "nav-link": true,
                            "link-accueil": true,
                            "link-active": _vm.linkActive === "puckbutton",
                            disabled:
                              !_vm.$store.state.connected ||
                              !_vm.$store.state.box_connected ||
                              !_vm.$store.state.start_config
                          },
                          attrs: { to: "/puckbutton" }
                        },
                        [_vm._v("Bouton")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          class: {
                            "nav-link": true,
                            "link-active": _vm.linkActive === "region",
                            disabled:
                              !_vm.$store.state.connected ||
                              !_vm.$store.state.box_connected ||
                              !_vm.$store.state.start_config
                          },
                          attrs: { to: "/region" }
                        },
                        [_vm._v("Région")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          class: {
                            "nav-link": true,
                            "link-active": _vm.linkActive === "sources",
                            disabled:
                              !_vm.$store.state.connected ||
                              !_vm.$store.state.box_connected ||
                              !_vm.$store.state.start_config
                          },
                          attrs: { to: "/sources" }
                        },
                        [_vm._v("Sources")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          class: {
                            "nav-link": true,
                            "link-active": _vm.linkActive === "informations",
                            disabled:
                              !_vm.$store.state.connected ||
                              !_vm.$store.state.box_connected ||
                              !_vm.$store.state.start_config
                          },
                          attrs: { to: "/informations" }
                        },
                        [_vm._v("Informations")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      staticStyle: { cursor: "pointer" }
                    },
                    [
                      _c("icon", {
                        staticClass: "icon-menu",
                        attrs: {
                          color: "white",
                          scale: "2",
                          name: "sign-out-alt"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/logout")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm.linkActive !== "home"
          ? _c(
              "div",
              {
                staticClass: "text-center mt-2 ",
                staticStyle: { "z-index": "1000" }
              },
              [
                _c("img", {
                  staticClass: "img-fluid",
                  staticStyle: { width: "150px" },
                  attrs: { src: "/images/logo-emotivi.png" }
                })
              ]
            )
          : _vm._e(),
        _vm.$store.state.connected
          ? _c(
              "div",
              {
                staticClass: "position-absolute",
                staticStyle: {
                  cursor: "pointer",
                  right: "1rem",
                  top: "1rem",
                  "z-index": "1000"
                }
              },
              [
                _c("icon", {
                  staticClass: "icon-menu",
                  attrs: { color: "#199aaa", scale: "2", name: "sign-out-alt" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/logout")
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.$store.state.connected && _vm.$route.name !== "home"
          ? _c(
              "div",
              {
                staticClass: "position-absolute",
                staticStyle: { cursor: "pointer", left: "1rem", top: "1rem" }
              },
              [
                _c("icon", {
                  staticClass: "icon-menu",
                  attrs: { color: "#199aaa", scale: "2", name: "home" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/home")
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticStyle: { overflow: "auto", height: "90vh" } },
        [_c("router-view")],
        1
      ),
      _c(
        "footer",
        { staticClass: "flex-grow-0", staticStyle: { height: "5vh" } },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row align-items-center px-2 px-md-5 small"
            },
            [
              _c("p", [
                _vm._v(" Design et développement : "),
                _c("span", { staticClass: "bis ml-2" }, [
                  _vm._v("La.team - " + _vm._s(_vm.theCurrentYear))
                ]),
                _vm._v(" - "),
                _c("span", { staticClass: "font-weight-bold text-secondary" }, [
                  _vm._v("v" + _vm._s(_vm.version))
                ])
              ])
            ]
          )
        ]
      ),
      _c("m-message-dialog", {
        attrs: { title: "Erreur", text: _vm.$store.state.dialogErrTxt },
        on: {
          close: function($event) {
            return _vm.$store.dispatch("showDialogError", false)
          }
        },
        model: {
          value: _vm.$store.state.dialogErr,
          callback: function($$v) {
            _vm.$set(_vm.$store.state, "dialogErr", $$v)
          },
          expression: "$store.state.dialogErr"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }