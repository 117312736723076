<template>
  <section>
    <div class="container mt-5" id="informations">
      <div class="row align-items-center">
        <div class="col" align="center">
          <div class="d-flex flex-row justify-content-center">
            <h1 class="mb-3 mr-3">Informations</h1>
            <div><icon name="info-circle" color="#91295a" scale="3"></icon></div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <button class="btn btn-warning uppercase" @click="startAnimation()" :disabled="!$store.state.box_connected">
                Terminer la configuration
              </button>
            </div>
          </div>
          <div class="row d-flex flex-column align-items-center mt-3">
            <div class="col">
              <div class="alert alert-info">
                <h3 class="">Votre Caméra</h3>
                <div v-if="camera && camera.plugged === 'yes'">
                  <p>Votre caméra est branchée.</p>
                  <button v-if="camera.on === 'yes'" class="btn btn-primary" @click="hideCamera()">Arrêter la caméra sur l'écran</button>
                  <button v-else class="btn btn-primary" @click="showCamera()">Tester la caméra sur l'écran</button>
                  <small class="form-text text-muted"
                    >Afficher la caméra du kit Emotivi sur la télévision afin de positionner au mieux la caméra.</small
                  >
                </div>
                <p v-else-if="camera && camera.plugged === 'no'" style="color: red">Votre caméra n'est pas branchée.</p>
                <div v-else>
                  <div class="spinner-border" role="status"></div>
                  <br />
                  <span>Nous sommes en train de vérifier l'état de la caméra...</span>
                </div>
              </div>

              <div class="alert alert-info">
                <h3 class="mb-3">Informations Réseau</h3>
                <div v-if="network">
                  <p id="networkStateText">
                    Votre bande passante descendante est de {{ network.upload.bandwidth }} Mb/s.<br />
                    Votre bande passante montante est de {{ network.download.bandwidth }} Mb/s.
                  </p>
                  <div v-if="parseFloat(network.download.bandwidth) < 5 || parseFloat(network.upload.bandwidth) < 5" style="color: red;">
                    <p>Votre connexion est très lente, nous ne garantissons pas la qualité du service.</p>
                  </div>
                  <div v-else-if="parseFloat(network.download.bandwidth) < 10 || parseFloat(network.upload.bandwidth) < 10" style="color: #FE9A01;">
                    <p>Votre connexion semble plutôt stable, mais quelques latances pourraient survenir pendant l'utilisation du service.</p>
                  </div>
                  <div v-else style="color: green;">
                    <p>Votre connexion est excellente ! Vous ne rencontrerez aucun problème pendant l'utilisation de notre service.</p>
                  </div>
                </div>
                <div v-else>
                  <div class="spinner-border" role="status"></div>
                  <br />
                  <span>Nous sommes en train de tester votre connexion internet...</span>
                </div>
              </div>

              <div class="alert alert-info">
                <h3 class="mb-3">Vos comptes</h3>
                <div v-if="informations" class="col-sm-12 col-md-5 ">
                  <h5>Votre compte famille :</h5>
                  <p style="margin-bottom:5px;">Identifiant : {{ informations.en_webapp_login }}</p>
                  <p>Mot de passe : {{ informations.en_webapp_password }}</p>
                  <div v-if="options.hasOwnProperty('options_display_connection_bar')" class="alert alert-warning">
                    <p>Souhaitez vous afficher les identifiants de connexion sur le téléviseur?</p>
                    <input type="checkbox" v-model="options.options_display_connection_bar" @change="saveOptions()" />
                    <span v-if="options.options_display_connection_bar">OUI</span>
                    <span v-else>NON</span>
                  </div>
                  <h5>Votre code administrateur pour emotivibox :</h5>
                  <p>Code administrateur : {{ informations.en_admincode }}</p>
                  <h5>Votre compte sur le site emotivi.fr :</h5>
                  <p>Identifiant : {{ informations.co_login }}</p>
                  <h5>Etat kit Emotivi : {{ informations.status }}</h5>
                  <p>Version kit Emotivi : {{ informations.appemotivi_version }} ({{ informations.hardware_memory }})</p>
                </div>
                <div v-else class="col-sm-12 col-md-5 mt-3">
                  <div class="spinner-border" role="status"></div>
                  <br />
                  <span>Nous sommes en train de récupérer les informations de vos comptes Emotivi...</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4 d-flex align-items-center">
              <button class="btn btn-warning" @click="previousStep()"><icon class="mr-2" name="arrow-left"></icon> Retour</button>
            </div>
            <div class="col-8 d-flex flex-row justify-content-end">
              <!-- <button class="btn btn-warning" @click="nextStep()">Suivant <icon class="ml-2" name="arrow-right"></icon></button> -->
              <button class="btn btn-warning uppercase" @click="startAnimation()" :disabled="!$store.state.box_connected">
                Terminer la configuration
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "informations",
  components: {},
  data() {
    return {
      sent: false,
      networkState: "",
      informations: {},
      network: null,
      camera: null,
      cameraShowing: false,
      options: {}
      // dataReady: { onload: false, camera: false, network: false, informations: false }
    };
  },
  async mounted() {
    window.scrollTo(0, 0);

    // console.log(this.$store.state.infos.status);
    this.dataReady = { onload: true, camera: false, network: false, informations: false };
    this.sendTvPage();
    this.$store.dispatch("getData", "camera");
    this.$store.dispatch("getData", "informations");
    this.$store.dispatch("getData", "network");
    this.$store.dispatch("getData", "options");
  },
  watch: {
    socket_response(response) {
      console.warn("SOCKET SHOW OK 2", response.cmd, response.data);
      if (response.cmd == "camera") {
        this.camera = response.data;
        this.dataReady.camera = true;
        this.sendTvPage();
        //this.$store.dispatch("sendInfosTvPages");
      }
      if (response.cmd == "network") {
        this.network = response.data;
        this.dataReady.network = true;
        this.sendTvPage();
        //this.$store.dispatch("sendInfosTvPages");
      }
      if (response.cmd == "informations") {
        this.informations = response.data;
        this.dataReady.informations = true;
        this.sendTvPage();
        //this.$store.dispatch("sendInfosTvPages");
      }
      if (response.cmd == "options") {
        this.options = response.data;
      }
    }
  },
  computed: {
    socket_response() {
      return this.$store.state.socket_response;
    }
  },
  methods: {
    sendTvPage() {
      let state = { ...this.informations, camera: this.camera ? this.camera.plugged : false, ...this.network };
      // console.log("state", state);
      this.$store.dispatch("sendTvPages", {
        key: "informations",
        html: "html_informations",
        state,
        replace: ""
      });
    },
    showCamera() {
      this.cameraShowing = true;
      this.$store.dispatch("setData", { cmd: "camera-show" });
    },
    hideCamera() {
      this.cameraShowing = false;
      this.$store.dispatch("setData", { cmd: "camera-hide" });
    },
    startAnimation() {
      if (this.cameraShowing) this.hideCamera();
      this.$store.dispatch("setData", { cmd: "start-animation" });
      // this.$router.push("/home");
      setTimeout(() => {
        window.open(this.$config.webapp_url + "/#/autosignin/" + this.$store.state.accesstoken, "_self");
      }, 1000);
    },
    previousStep() {
      this.hideCamera();
      this.$router.push("/sources");
    },
    saveOptions() {
      this.$store.dispatch("setData", { cmd: "options", data: { options: this.options } });
    }
  }
  // computed: {
  //   // network() {
  //   //   return this.$store.state.network;
  //   // },
  //   // camera() {
  //   //   return this.$store.state.camera;
  //   // },
  //   // infos() {
  //   //   return this.$store.state.infos;
  //   // }
  // },
  // watch: {
  //   // network: {
  //   //   handler(value) {
  //   //     if (value && this.camera && this.infos && !this.sent) {
  //   //       this.sent = true;
  //   //       this.$store.dispatch("sendInfosTvPages");
  //   //     }
  //   //   },
  //   //   deep: true
  //   // },
  //   // camera: {
  //   //   handler(value) {
  //   //     if (value && this.network && this.infos && !this.sent) {
  //   //       this.sent = true;
  //   //       this.$store.dispatch("sendInfosTvPages");
  //   //     }
  //   //   },
  //   //   deep: true
  //   // },
  //   // infos: {
  //   //   handler(value) {
  //   //     if (value && this.camera && this.network && !this.sent) {
  //   //       this.sent = true;
  //   //       this.$store.dispatch("sendInfosTvPages");
  //   //     }
  //   //   },
  //   //   deep: true
  //   // }
  // }
};
</script>
<style scoped>
.img-titre-infos {
  margin-top: -10px;
  width: 60px;
  height: 60px;
  background-image: url("/images/informations.png");
  background-size: cover;
  background-position: center;
}
</style>
