var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row mt-5", attrs: { align: "center" } }, [
      _c("div", { staticClass: "col" }, [
        _c("img", {
          staticClass: "img-fluid",
          staticStyle: { width: "200px" },
          attrs: { src: "/images/logo-emotivi.png" }
        }),
        _c("div", [_c("icon", { attrs: { name: "home", scale: "3" } })], 1),
        _vm._m(0),
        _vm.$store.state.box_connected
          ? _c("div", { staticClass: "alert alert-info" }, [
              _c("div", [_vm._v("Vous êtes connecté à la box")]),
              _c("div", [_vm._v(_vm._s(_vm.allproblems))]),
              _c("div", { staticClass: "mt-3" }, [
                !_vm.$store.state.start_config
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary uppercase",
                        attrs: { disabled: !_vm.$store.state.box_connected },
                        on: {
                          click: function($event) {
                            return _vm.startConfig()
                          }
                        }
                      },
                      [_vm._v(" Lancer la configuration sur la télévision ")]
                    )
                  : _vm._e(),
                !_vm.$store.state.start_config
                  ? _c("small", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        "Lancer la configuration, affichera les différentes pages de configuration sur la télévision. Il est conseillé d'être devant l'écran de télévision."
                      )
                    ])
                  : _vm._e(),
                _vm.$store.state.start_config
                  ? _c(
                      "div",
                      { staticClass: "row w-100 flex-wrap" },
                      [
                        _c("m-home-icon", {
                          attrs: {
                            name: "globe",
                            link: "region",
                            label: "Fuseau horaire",
                            bgcolor: "#189baa",
                            scale: 3
                          }
                        }),
                        _c("m-home-icon", {
                          attrs: {
                            name: "circle",
                            link: "puckbutton",
                            label: "Bouton",
                            bgcolor: "#189baa",
                            scale: 3
                          }
                        }),
                        _c("m-home-icon", {
                          attrs: {
                            name: "broadcast-tower",
                            link: "sources",
                            label: "Sources",
                            bgcolor: "#189baa",
                            scale: 3
                          }
                        }),
                        _c("m-home-icon", {
                          attrs: {
                            name: "info-circle",
                            link: "informations",
                            label: "Informations",
                            bgcolor: "#189baa",
                            scale: 3
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          : _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(
                "Vous n'êtes pas connecté à la box, veuillez l'allumer et/ou la connecter à Internet"
              )
            ]),
        _vm.$store.state.start_config
          ? _c(
              "button",
              {
                staticClass: "btn btn-warning uppercase mt-3",
                attrs: { disabled: !_vm.$store.state.box_connected },
                on: {
                  click: function($event) {
                    return _vm.startAnimation()
                  }
                }
              },
              [_vm._v(" Terminer la configuration maintenant ")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-3" }, [
      _c("p", [_vm._v("Bienvenue sur la page de configuration du kit Emotivi")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }