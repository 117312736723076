<template>
  <div
    class="col m-1 p-2 d-flex flex-column justify-content-center align-items-center rounded"
    :style="`cursor: pointer; background-color: ${bgcolor}`"
    @click="$router.push('/' + link)"
  >
    <img v-if="image" class="image-icon mt-2" :src="image" :style="`width:${scale}rem; height:${scale}rem;`" />
    <icon v-else class="mt-2" :color="color" :scale="scale" :name="name"></icon>
    <span :class="`text-${color}`">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "mhomeicon",
  props: {
    name: {
      default: "home",
      type: String
    },
    image: String,
    label: {
      default: "Accueil",
      type: String
    },
    scale: {
      default: 2,
      type: Number
    },
    link: {
      default: "home",
      type: String
    },
    color: {
      default: "white",
      type: String
    },
    bgcolor: {
      default: "#199aaa",
      type: String
    }
  },
  mounted() {},
  destroyed() {
    window.removeEventListener("click", this.onWindowClick);
  },
  data() {
    return {};
  },

  components: {},
  methods: {}
};
</script>
<style>
.image-icon {
  width: inherit;
  filter: invert(1) brightness(100);
}
</style>
