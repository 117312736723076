<template>
  <div class="form-group textarea-markdown">
    <label
      :class="{
        label: true
      }"
      :for="id2"
      v-if="label"
      >{{ label }}</label
    >
    <div class="container-btn-markdown">
      <div class="d-flex flex-row justify-content-end mr-4">
        <div @click="bold" class="container-icon ml-3">
          <icon name="bold"></icon>
        </div>
        <div @click="italic" class="container-icon ml-3">
          <icon name="italic"></icon>
        </div>
        <div @click="color" class="container-icon ml-3">
          <icon name="tint"></icon>
        </div>
      </div>
    </div>
    <div class="input-group">
      <!--  <div class="input-group-prepend" v-if="prependText">
        <span class="input-group-text" v-html="prependText"></span>
      </div> -->
      <textarea
        ref="myinput"
        :aria-haspopup="ariaHaspopup"
        :aria-expanded="ariaExpanded"
        :rows="rows"
        :name="name"
        class="textarea"
        :class="{
          'form-control': true,
          'is-invalid': errormsg2
        }"
        :id="id2"
        :disabled="disabled"
        aria-describedby
        :placeholder="placeholder"
        :value="value2"
        :autocomplete="autocomplete"
        @click="onclick($event)"
        @input="oninput($event)"
        @focus="onfocus($event)"
        @blur="onblur($event)"
        @keyup="onkeyup($event)"
        @keydown="onkeydown($event)"
        @select="selectText"
      />
      <!-- <div class="input-group-prepend" v-if="appendText || appendIcon">
                <span
          v-if="appendText"
          class="input-group-text"
          v-html="appendText"
        ></span>
        <span v-if="appendIcon" class="input-group-text">
          <icon class="icon-menu" :name="appendIcon"></icon>
        </span>
      </div> -->
      <div :id="id2" class="invalid-feedback" v-if="errormsg2">
        {{ errormsg2 }}
      </div>
    </div>
    <div class="btn-help" @click="helpMarkdown = !helpMarkdown">
      Afficher l'aide du markdown
    </div>
    <div v-if="helpMarkdown">
      <p>
        - Pour faire un nouveau paragraphe, sauter deux lignes, c'est à dire laisser une ligne vide entre les deux paragraphes. Sauter une seule ligne
        dans le texte d'origine n'aura aucun effet à l'affichage (l'affichage sera en continu).<br />
        - Pour faire un simple retour à la ligne, mettre deux espaces en fin de ligne.<br />
        - Pour mettre du texte en grande emphase, ce qui produit en général une mise en gras : **<span class="italic">votre texte en gras</span>**
        <br />
        <!-- - Pour souligner du texte :
         __<span class="italic">texte souligné</span>__ <br> -->
        - Pour faire une ligne de séparation hr : sauter une ligne puis mettre une série de tirets : -------------
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "mformtext",
  props: {
    value: [String, Number],
    id: {
      default: "",
      type: String
    },
    /*     type: {
      default: "text",
      type: String
    }, */
    name: {
      default: "",
      type: String
    },
    rows: {
      default: 4,
      type: Number
    },
    autocomplete: {
      default: "new-password",
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    autoSelectOnFocus: {
      default: true,
      type: Boolean
    },
    label: {
      default: "",
      type: String
    },
    tooltip: {
      default: "",
      type: String
    },
    tooltipPosition: {
      default: "bottom",
      type: String
    },
    ariaHaspopup: {
      default: false,
      type: Boolean
    },
    ariaExpanded: {
      default: false,
      type: Boolean
    },
    rules: {
      default: function() {
        return [];
      },
      type: Array
    },
    placeholder: String,
    /* prependIcon: String,
    appendIcon: String, 
    prependText: String,*/
    appendText: String
    /* subText: String */
  },
  mounted() {
    if (this.id2) this.id2 = this.id;
    else this.id2 = this.$Utils.randomstring();
    // if (this.value2) this.id2 = this.id;
    // else this.id2 = this.$Utils.randomstring();
    window.addEventListener("click", this.onWindowClick);
  },
  destroyed() {
    window.removeEventListener("click", this.onWindowClick);
  },
  data() {
    return {
      id2: "",
      value2: "",
      errormsg2: "",
      focus: false,
      helpMarkdown: false,
      selection: ""
    };
  },
  watch: {
    value: function(val) {
      // if (val) this.focus = true;
      this.value2 = val;
    }
  },
  components: {},
  methods: {
    onWindowClick(evt) {
      // si je click sur le document
      if (!evt.target.name) {
        //this.showDropdown = false;
        this.$emit("hideOrShowDropdown", false);
      }
      let input = this.$store.getters.get_inputOpened;
      // si je click un imput
      if (input && input.name !== this.name) {
        //this.showDropdown = false;
        this.$emit("hideOrShowDropdown", false);
      }
    },
    informValid() {
      this.errormsg2 = "";
    },
    informInvalid(txt) {
      this.errormsg2 = txt;
    },
    validate() {
      let kos = [];
      for (let iRule = 0; iRule < this.rules.length; iRule++) {
        const rule = this.rules[iRule];
        let ok = rule(this.value2);
        if (this.$_.isString(ok)) kos.push(ok);
      }
      if (kos.length == 0) {
        this.informValid();
        return true;
      }
      this.informInvalid(kos.join(", "));
      return false;
    },
    onkeyup(evt) {
      this.$emit("keyup", evt);
    },
    onkeydown(evt) {
      this.$emit("keydown", evt);
    },
    oninput(evt) {
      let v = evt.target.value;
      if (this.type == "number" && v) {
        if ((v + "").indexOf(",")) v = (v + "").replace(/\,/, ".");
        v = v * 1;
      }
      this.value2 = v;
      // console.log("this.value2", this.value2);
      this.validate();
      this.$emit("input", this.value2, evt);
    },
    onclick(evt) {
      this.$emit("hideOrShowDropdown", true);
      let input = this.$store.getters.get_inputOpened;
      if (!input || (input && input.name !== evt.target.name)) {
        this.$store.commit("set_inputOpened", this);
      }
      this.$emit("click", evt);
    },
    onfocus(evt) {
      if (this.type !== "textarea") this.focus = true;
      this.$emit("hideOrShowDropdown", true);
      if (this.autoSelectOnFocus) evt.target.select();
      let input = this.$store.getters.get_inputOpened;
      if (!input || (input && input.name !== evt.target.name)) {
        this.$store.commit("set_inputOpened", this);
      }
      this.$emit("focus", evt);
    },
    onblur() {
      if (!this.value2 || !this.value) {
        this.focus = false;
      }
    },
    hideDropdown() {
      this.$emit("hideOrShowDropdown", false);
    },
    selectText(event) {
      this.selection = window.getSelection().toString();
    },
    bold() {
      let regex = new RegExp(this.selection, "g");
      let replace = "**" + this.selection + "**";
      // console.log("regex", regex, replace);
      this.value2.replace(regex, replace);
    },
    italic() {},
    color() {}
  }
};
</script>

<style lang="scss">
.form-control {
  // border-radius: 0;
  // border-radius: 0;
  // border: 0px;
  // border-bottom: 1px solid #999999;
  background-color: #ffffff00;
  height: 30px;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  margin-bottom: 0px;
}
.textarea {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
}
.form-group {
  position: relative;
  margin: 25px 0 0 0;
}
.textarea-markdown .label {
  position: relative;
}
/* 
.label_focus {
  top: -20px;
  font-size: 12px;
  color: gray;
}
.textarea_label {
  position: relative;
} */
.m-form-base {
  .m-form-base__container-input {
    textarea {
      border-radius: 5px;
      border: 1px solid #eaeaea;
    }
  }
}
.sub-text {
  color: #4950579c;
}
.container-btn-markdown {
  width: 100%;
  border: 1px solid #eaeaea;
  padding: 8px;
}
.container-icon {
  cursor: pointer;
}
.btn-help {
  width: 100%;
  border: 1px solid #eaeaea;
  padding: 5px;
  cursor: pointer;
}
</style>
