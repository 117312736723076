<template>
  <section>
    <div class="container mt-5" id="sources">
      <div class="row align-items-center">
        <div class="col" align="center">
          <div class="d-flex flex-row justify-content-center">
            <h1 class="mb-3 mr-3">Configuration des sources</h1>
            <div><icon name="broadcast-tower" color="#91295a" scale="3"></icon></div>
          </div>
          <div class="form-group alert alert-info">
            <label class="" for="sourceBox">Port HDMI du kit Emotivi :</label>
            <select id="sourceBox" name="sourceBox" class="custom-select" v-model="sources.source_emotivi" @change="saveData()">
              <option value="">Sélectionnez un numéro.</option>
              <option value="0">Aucun</option>
              <option v-for="source in sourceListEmotivi" :key="source.key" :value="source.key">{{ source.name }}</option>
            </select>
            <small class="form-text text-muted"
              >Sélectionner le numéro de la prise HDMI de votre télévision sur laquelle le kit Emotivi est branché (sur la télévision et non pas sur
              le kit).</small
            >
          </div>
          <!-- <hr class="hr-white" /> -->

          <div class="form-group alert alert-info">
            <label class="" for="sourceTv">Sources des chaînes TV</label>
            <select id="sourceTv" name="sourceTv" class="custom-select" v-model="sources.source_tv" @change="saveData()">
              <option value="">Sélectionnez une source.</option>
              <option value="0">Aucun</option>
              <option v-for="source in sourceListTv" :key="source.key" :value="source.key">{{ source.name }}</option>
            </select>
            <small class="form-text text-muted"
              >Sélectionner la source de votre télévision ; pour connaitre la source, grace à votre télécommande, mettez la télévision et observer la
              source (généralement TV ou HDMI2)</small
            >
          </div>
          <!-- <hr class="hr-white" /> -->

          <div class="form-group alert alert-info">
            <label>Choissisez votre modèle de télévision</label>
            <select id="sourceTV" name="sourceTV" class="custom-select" v-model="sources.source_tvbrand" @change="saveData('tvbrand')">
              <option value="">Sélectionnez un modèle de télévision.</option>
              <option value="other">Autre modèle</option>
              <option v-for="tvbrand in tvbrands" :key="tvbrand.tt_key" :value="tvbrand.tt_key">{{ tvbrand.tt_name }}</option>
            </select>
            <small class="form-text text-muted">
              Dans certain cas, nous avons besoin d'utiliser l'infra-rouge (comme votre télécommande) afin de changer la source de votre télévision.
              Pour cela, nous avons besoin de connaitre le modèle de votre télévision.
            </small>
          </div>

          <button class="btn btn-primary mt-3" @click="testing('totv')">Tester le changement de source Emotivi -> TV</button>
          <button class="btn btn-primary mt-2" @click="testing('toemotivi')">Tester le changement de source TV -> EmotiviBox</button>
          <button class="btn btn-primary mt-2" @click="testing('switchoff')">Tester l'extinction de la télévision</button>
          <button class="btn btn-primary mt-2" @click="testing('switchon')">Tester l'allumage de la télévision</button>

          <div class="mt-3">
            <!-- <h6>Options supplémentaires</h6> -->
            <button class="btn btn-primary2 w-100" @click="showMoreOptions = !showMoreOptions">
              Options avançées et aide <icon class="ml-2" name="chevron-down"></icon>
            </button>
          </div>
          <transition name="fade">
            <div v-if="showMoreOptions" class="text-left">
              <h5 class="mt-3">Source Emotivi</h5>
              <p v-if="sources.source_emotivi_use_cec && sources.source_emotivi_use_ir" class="alert alert-warning">
                Attention vous avez sélectionné le CEC et l'infrarouge pour le changement de source vers Emotivi, il se pourrait que le téléviseur ait
                un comportement inattendu.
              </p>
              <div class="form-check">
                <input
                  type="checkbox"
                  id="checkboxEmotiviUseCec"
                  class="form-check-input"
                  v-model="sources.source_emotivi_use_cec"
                  checked
                  @change="saveData()"
                />
                <label class="form-check-label" for="checkboxEmotiviUseCec">Utiliser la prise HDMI (CEC) pour TV -> Emotivi</label>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  id="checkboxEmotiviUseIr"
                  class="form-check-input"
                  v-model="sources.source_emotivi_use_ir"
                  checked
                  @change="saveData()"
                />
                <label class="form-check-label" for="checkboxEmotiviUseIr">Utiliser l'infrarouge pour TV -> Emotivi</label>
              </div>

              <div v-if="sources.source_emotivi_macro !== undefined" class="form-group alert alert-secondary border">
                <label>Choisissez une macro infrarouge si nécessaire</label>
                <select
                  id="sourceEmotiviMacro"
                  name="sourceEmotiviMacro"
                  class="custom-select"
                  v-model="sources.source_emotivi_macro"
                  @change="saveData()"
                >
                  <option value="">Aucune</option>
                  <option v-for="macro in tvBrandMacrosToEmotivi" :key="macro.ma_key" :value="macro.ma_key">{{ macro.ma_label }}</option>
                </select>
              </div>

              <h5 class="mt-3">Source des chaînes TV</h5>
              <p v-if="sources.source_tv_use_cec && sources.source_tv_use_ir" class="alert alert-warning">
                Attention vous avez sélectionné le CEC et l'infrarouge pour le changement de source vers les chaînes TV, il se pourrait que le
                téléviseur ait un comportement inattendu.
              </p>
              <div class="form-check">
                <input
                  type="checkbox"
                  id="checkboxTvUseCec"
                  class="form-check-input"
                  v-model="sources.source_tv_use_cec"
                  checked
                  @change="saveData()"
                />
                <label class="form-check-label" for="checkboxTvUseCec">Utiliser la prise HDMI (CEC) pour Emotivi -> TV</label>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  id="checkboxTvUseIr"
                  class="form-check-input"
                  v-model="sources.source_tv_use_ir"
                  checked
                  @change="saveData()"
                />
                <label class="form-check-label" for="checkboxTvUseIr">Utiliser l'infrarouge pour Emotivi -> TV</label>
              </div>
              <div v-if="sources.source_tv_macro !== undefined" class="form-group alert alert-secondary border">
                <label>Choisissez une macro infrarouge si nécessaire</label>
                <select id="sourceTVMacro" name="sourceTVMacro" class="custom-select" v-model="sources.source_tv_macro" @change="saveData()">
                  <option value="">Aucune</option>
                  <option v-for="macro in tvBrandMacrosToTv" :key="macro.ma_key" :value="macro.ma_key">{{ macro.ma_label }}</option>
                </select>
              </div>
              <div v-if="sources.source_switch_on_use_cec !== undefined">
                <h5 class="mt-3">Allumage de la TV</h5>
                <p v-if="sources.source_switch_on_use_cec && sources.source_switch_on_use_ir" class="alert alert-warning">
                  Attention vous avez sélectionné le CEC et l'infrarouge pour l'allumage, il se pourrait que le téléviseur ait un comportement
                  inattendu.
                </p>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="checkboxSwitchOnUseCec"
                    class="form-check-input"
                    v-model="sources.source_switch_on_use_cec"
                    checked
                    @change="saveData()"
                  />
                  <label class="form-check-label" for="checkboxSwitchOnUseCec">Utiliser la prise HDMI (CEC) pour l'allumage de la TV</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="checkboxSwitchOnUseIr"
                    class="form-check-input"
                    v-model="sources.source_switch_on_use_ir"
                    @change="saveData()"
                  />
                  <label class="form-check-label" for="checkboxSwitchOnUseIr">Utiliser l'infrarouge pour l'allumage de la TV</label>
                </div>
              </div>
              <div v-if="sources.source_switch_off_use_cec !== undefined">
                <h5 class="mt-3">Extinction de la TV</h5>
                <p v-if="sources.source_switch_off_use_cec && sources.source_switch_off_use_ir" class="alert alert-warning">
                  Attention vous avez sélectionné le CEC et l'infrarouge pour l'extinction, il se pourrait que le téléviseur ait un comportement
                  inattendu.
                </p>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="checkboxSwitchOffUseCec"
                    class="form-check-input"
                    v-model="sources.source_switch_off_use_cec"
                    checked
                    @change="saveData()"
                  />
                  <label class="form-check-label" for="checkboxSwitchOnUseCec">Utiliser la prise HDMI (CEC) pour l'extinction de la TV</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="checkboxSwitchOffUseIr"
                    class="form-check-input"
                    v-model="sources.source_switch_off_use_ir"
                    @change="saveData()"
                  />
                  <label class="form-check-label" for="checkboxSwitchOnUseIr">Utiliser l'infrarouge pour l'extinction de la TV</label>
                </div>
              </div>
              <div v-if="sources.source_cec_delay !== undefined" class="form-group">
                <label class="" for="cecDelay">Délai entre l'envoi des intructions HDMI (CEC) :</label>
                <input class="form-control" type="number" id="cecDelay" v-model="sources.source_cec_delay" @change="saveData()" />
              </div>
              <div class="form-group">
                <label class="" for="tvUseCec">Durée de l'envoi des intructions infrarouge : </label>
                <input class="form-control" type="number" id="tvUseCec" v-model="sources.source_ir_duration" @change="saveData()" />
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="checkboxIrImpulse" v-model="sources.source_ir_repeat" @change="saveData()" />
                <label class="form-check-label" for="checkboxIrImpulse">Envoyer plusieurs fois l'instruction infrarouge</label>
              </div>
              <div class="form-group">
                <label class="" for="irDelay">Délai entre l'envoi des intructions infrarouge :</label>
                <input class="form-control" type="number" id="irDelay" v-model="sources.source_ir_delay" @change="saveData()" />
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="checkboxIgnorecec" v-model="sources.source_ignorecec" @change="saveData()" />
                <label class="form-check-label" for="checkboxIgnorecec">Ignorer la vérification de l'état de la télévision lors d'un appel.</label>
              </div>
              <div class="text-align-left mt-4">
                <h5>Nom du CEC par marques</h5>
                <p>
                  Vous devez peut-être activer les options CEC dans votre téléviseurs. Aidez-vous de cette liste pour activer le CEC sur votre
                  télévision
                </p>
                <ul>
                  <li>AOC: E-link</li>
                  <li>Hitachi: HDMI-CEC</li>
                  <li>Insignia: (INlink)</li>
                  <li>ITT: T-Link</li>
                  <li>LG: SimpLink</li>
                  <li>Mitsubishi: NetCommand for HDMI</li>
                  <li>Onkyo: RIHD (Remote Interactive over HDMI)</li>
                  <li>Panasonic: HDAVI Control, EZ-Sync, or VIERA Link</li>
                  <li>Philips: EasyLink</li>
                  <li>Pioneer: Kuro Link</li>
                  <li>Runco International: RuncoLink</li>
                  <li>Samsung: Anynet+</li>
                  <li>Sharp: Aquos Link</li>
                  <li>Sony: BRAVIA Sync and BRAVIA Sync</li>
                  <li>Toshiba: CE-Link and Regza Link</li>
                  <li>Vizio: CEC</li>
                </ul>
              </div>

              <div class="form-group row">
                <label class="col-6" for="source_pronto_switch_on">Pronto allumer :</label>
                <input
                  class="col form-control"
                  type="text"
                  id="source_pronto_switch_on"
                  v-model="sources.source_pronto_switch_on"
                  @change="saveData()"
                />
              </div>
              <div class="form-group row">
                <label class="col-6" for="source_pronto_switch_off">Pronto eteindre :</label>
                <input
                  class="col form-control"
                  type="text"
                  id="source_pronto_switch_off"
                  v-model="sources.source_pronto_switch_off"
                  @change="saveData()"
                />
              </div>
              <div class="form-group row">
                <label class="col-6" for="source_pronto_emotivi">Pronto vers Emotivi :</label>
                <input class="col form-control" type="text" id="source_pronto_emotivi" v-model="sources.source_pronto_emotivi" @change="saveData()" />
              </div>
              <div class="form-group row">
                <label class="col-6" for="source_pronto_tv">Pronto vers TV :</label>
                <input class="col form-control" type="text" id="source_pronto_tv" v-model="sources.source_pronto_tv" @change="saveData()" />
              </div>
              <div class="form-group row">
                <label class="col-6" for="source_pronto_sound_plus">Pronto son UP :</label>
                <input
                  class="col form-control"
                  type="text"
                  id="source_pronto_sound_plus"
                  v-model="sources.source_pronto_sound_plus"
                  @change="saveData()"
                />
              </div>
              <div class="form-group row">
                <label class="col-6" for="source_pronto_sound_minus">Pronto son DOWN :</label>
                <input
                  class="col form-control"
                  type="text"
                  id="source_pronto_sound_minus"
                  v-model="sources.source_pronto_sound_minus"
                  @change="saveData()"
                />
              </div>
            </div>
          </transition>

          <div class="row mt-4">
            <div class="col-6 d-flex flex-row justify-content-left">
              <button class="btn btn-warning" @click="previousStep()"><icon class="mr-2" name="arrow-left"></icon> Retour</button>
            </div>
            <div class="col-6 d-flex flex-row justify-content-end">
              <button class="btn btn-warning" @click="nextStep()">Suivant <icon class="ml-2" name="arrow-right"></icon></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "sources",
  components: {},
  data() {
    return {
      showOptionsBoxEmotivi: false,
      showOptionsTv: false,
      showMoreOptions: false,
      sources: {},
      tvbrands: [],
      tvBrandsData: {},
      tvBrandsFiles: [],
      tvBrandMacros: [],
      tvBrandMacrosToEmotivi: [],
      tvBrandMacrosToTv: [],
      sourceListEmotivi: [
        { key: "hdmi1", name: "HDMI 1" },
        { key: "hdmi2", name: "HDMI 2" },
        { key: "hdmi3", name: "HDMI 3" },
        { key: "hdmi4", name: "HDMI 4" }
      ],
      sourceListTv: [
        { key: "hdmi1", name: "HDMI 1" },
        { key: "hdmi2", name: "HDMI 2" },
        { key: "hdmi3", name: "HDMI 3" },
        { key: "hdmi4", name: "HDMI 4" },
        { key: "antenna1", name: "Antenne 1 (TV)" },
        { key: "av1", name: "AV 1" }
      ],
      tvMacros: []
    };
  },
  async mounted() {
    window.scrollTo(0, 0);

    this.$store.commit("set_mainloading", true);
    this.tvBrandsData = await this.$store.dispatch("getTvBrands");
    this.tvbrands = this.tvBrandsData.tvbrands;
    this.tvBrandsFiles = this.tvBrandsData.tvbrandsfiles;
    // this.tvMacros = this.tvBrandsData.macros;
    this.$store.dispatch("getData", "sources");
  },
  watch: {
    async socket_response(response) {
      console.warn("SOCKET SHOW OK 2", response.cmd, response.data);
      if (response.cmd == "sources") {
        if (!response.data.source_pronto_switch_on) response.data.source_pronto_switch_on = "";
        if (!response.data.source_pronto_switch_off) response.data.source_pronto_switch_off = "";
        if (!response.data.source_pronto_emotivi) response.data.source_pronto_emotivi = "";
        if (!response.data.source_pronto_tv) response.data.source_pronto_tv = "";
        if (!response.data.source_pronto_sound_plus) response.data.source_pronto_sound_plus = "";
        if (!response.data.source_pronto_sound_minus) response.data.source_pronto_sound_minus = "";
        this.sources = response.data;
        // console.log("this.sources.source_tvbrand", this.sources.source_tvbrand);
        await this.getMacrosTv(this.sources.source_tvbrand);
        await this.setTvBrandMacros();

        this.$store.dispatch("sendTvPages", {
          key: "sources",
          html: "html_sources",
          state: {
            source_emotivi: this.$_.result(this.$_.find(this.sourceListEmotivi, { key: this.sources.source_emotivi }), "name", ""),
            source_tv: this.$_.result(this.$_.find(this.sourceListTv, { key: this.sources.source_tv }), "name", ""),
            source_tvbrand: this.$_.result(this.$_.find(this.tvbrands, { tt_key: this.sources.source_tvbrand }), "tt_name", "Autre modèle")
          },
          replace: ""
        });
        this.$store.commit("set_mainloading", false);
      }
    }
  },
  computed: {
    socket_response() {
      return this.$store.state.socket_response;
    }

    // sources() {
    //   return this.$store.state.sources;
    // }
  },
  methods: {
    async saveData(what) {
      this.sources.source_pronto_switch_on = this.sources.source_pronto_switch_on.trim();
      this.sources.source_pronto_switch_off = this.sources.source_pronto_switch_off.trim();
      this.sources.source_pronto_emotivi = this.sources.source_pronto_emotivi.trim();
      this.sources.source_pronto_tv = this.sources.source_pronto_tv.trim();
      this.sources.source_pronto_sound_plus = this.sources.source_pronto_sound_plus.trim();
      this.sources.source_pronto_sound_minus = this.sources.source_pronto_sound_minus.trim();
      if (what === "tvbrand") {
        await this.getMacrosTv(this.sources.source_tvbrand);
        await this.setTvBrandMacros();
        // let source_emotivi_macro = "";
        // let source_tv_macro = "";
        // if (this.tvBrandMacros && Array.isArray(this.tvBrandMacros) && this.tvBrandMacros.length) {
        //   this.tvBrandMacros.forEach(brandmacro => {
        //     if (brandmacro.key === this.sources.source_emotivi_macro) source_emotivi_macro = brandmacro.key;
        //     if (brandmacro.key === this.sources.source_tv_macro) source_tv_macro = brandmacro.key;
        //   });
        // }
        // this.sources.source_emotivi_macro = source_emotivi_macro;
        // this.sources.source_tv_macro = source_tv_macro;
      }
      await this.$store.dispatch("setData", { cmd: "sources", data: { sources: this.sources } });
    },
    previousStep() {
      this.$router.push("/puckbutton");
    },
    async nextStep() {
      if (
        this.sources.source_emotivi != "" &&
        this.sources.source_emotivi != "0" &&
        this.sources.source_tv != "" &&
        this.sources.source_tvbrand != ""
      ) {
        this.$router.push("/informations");
      } else {
        let text = "";
        if (this.sources.source_emotivi === "" || this.sources.source_emotivi === "0")
          text = "Vous n'avez pas configuré la source Emotivi. Souhaitez-vous continuer ?";
        else if (this.sources.source_tv === "") text = "Vous n'avez pas configuré la source des chaînes TV. Souhaitez-vous continuer ?";
        else if (this.sources.source_tvbrand === "")
          text =
            "Vous n'avez pas selectionné votre modèle de télévision, sélectionnez 'Aucun', si vous ne le trouvez pas dans la liste. Souhaitez-vous continuer ?";
        let ok = true;
        if (text) ok = window.confirm(text);
        if (ok) this.$router.push("/informations");
      }
    },
    async testing(target) {
      await this.$store.dispatch("setData", { cmd: "sources-test-" + target });
    },
    setTvBrandMacros() {
      this.tvBrandMacros = [];
      this.tvBrandMacrosToEmotivi = [];
      this.tvBrandMacrosToTv = [];
      if (Array.isArray(this.tvMacros) && this.tvMacros.length) {
        this.tvMacros.forEach(tvMacro => {
          if (!tvMacro.ma_label && this.tvBrandsData && Array.isArray(this.tvBrandsData.macros) && this.tvBrandsData.macros.length) {
            let genericLabel = this.tvBrandsData.macros.find(_macro => {
              if (_macro.ma_key === tvMacro.ma_key && _macro.ma_label) return _macro;
            });
            if (genericLabel) tvMacro.ma_label = genericLabel.ma_label;
          }
          if (tvMacro.ma_key.indexOf("to_emotivi") >= 0) this.tvBrandMacrosToEmotivi.push(tvMacro);
          else if (tvMacro.ma_key.indexOf("to_tv") >= 0) this.tvBrandMacrosToTv.push(tvMacro);
        });
      }
    },
    async getMacrosTv(sourcetv) {
      let response = await this.$axios.get(this.$config.server_url + "/config/2.0/tvmacros/" + sourcetv);
      if (response.data) {
        this.tvMacros = response.data.data;
      }
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.img-titre {
  margin-top: -10px;
  width: 40px;
  height: 60px;
  background-image: url("/images/sources.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
