<template>
  <section>
    <div class="container mt-5" id="resolution">
      <div class="row align-items-center">
        <div class="col" align="center">
          <div class="d-flex flex-row justify-content-center">
            <h1 class="mb-5 mr-3">Configuration régionale</h1>
            <!-- <div class="img-titre-resolution"></div> -->
            <div><icon name="globe" color="#91295a" scale="3"></icon></div>
          </div>
          <div class="alert alert-info">
            <div class="form-group mt-0">
              <label for="resolutionBox" class="">Choix de votre langue</label>
              <select id="resolutionBox" name="resolutionBox" class="custom-select" v-model="region.lang" @change="saveData()">
                <option v-for="lang in langs" :key="lang.key" :value="lang.key" selected>{{ lang.name }}</option>
              </select>
              <small class="form-text text-muted"
                >Emotivi gérera très prochainement l'anglais, l'allemand, l'italien, l'espagnol, le portugais.</small
              >
            </div>
          </div>
          <div class="alert alert-info">
            <div class="form-group mt-0">
              <label for="resolutionBox" class="">Fuseau horaire</label>
              <select id="resolutionBox" name="resolutionBox" class="custom-select" v-model="region.timezone" @change="saveData()">
                <option v-for="zone in timezones" :key="zone.key" :value="zone.key">{{ zone.name_fr }} ({{ zone.offset }})</option>
              </select>
              <small class="form-text text-muted"
                >Emotivi gérera très prochainement l'anglais, l'allemand, l'italien, l'espagnol, le portugais.</small
              >
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 d-flex flex-row justify-content-left">
              <button class="btn btn-warning" @click="previousStep()"><icon class="mr-2" name="arrow-left"></icon> Retour</button>
            </div>
            <div class="col-6 d-flex flex-row justify-content-end">
              <button class="btn btn-warning" @click="nextStep()">Suivant <icon class="ml-2" name="arrow-right"></icon></button>
            </div>
          </div>
          <div class="mt-5">
            <!-- <button class="btn btn-primary w-100 mb-4" @click="testingResolution()">
              TESTER LA RÉSOLUTION
            </button> -->
            <!-- <button class="btn btn-primary w-100" @click="nextStep()">
              OK
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "resolution",
  components: {},
  data() {
    return {
      region: { timezone: "Europe/Paris", lang: "fr" },
      timezones: [
        // { key: 1, name: "France métropolitaine - Paris", utc: "+01.00" },
        // { key: 2, name: "France - Guyane et Saint-Pierre-et-Miquelon", utc: "-03.00" },
        // { key: 3, name: "France - Nouvelle-Calédonie", utc: "+11.00" },
        // { key: 4, name: "France - Mayotte", utc: "+03.00" }
      ],
      langs: [{ key: "fr", name: "Français" }]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.commit("set_mainloading", true);
    this.$store.dispatch("getData", "region");
  },
  watch: {
    socket_response(response) {
      console.warn("SOCKET SHOW OK 2", response);
      if (response.cmd === "region") {
        this.region = response.data;
        this.timezones = response.timezones;
        this.$store.dispatch("sendTvPages", {
          key: "region",
          html: "html_region",
          state: {
            timezone: this.region.timezone,
            lang: "Français"
          },
          replace: ""
        });
        this.$store.commit("set_mainloading", false);
      }
    }
  },
  computed: {
    socket_response() {
      return this.$store.state.socket_response;
    }
  },
  methods: {
    async saveData() {
      console.log("this.region", this.region);
      if (!this.region.timezone) this.region.timezone = "Europe/Paris";
      if (!this.region.lang) this.region.lang = "fr";
      console.warn("this.region", this.region);
      this.$store.dispatch("setData", { cmd: "region", data: this.region });
    },
    previousStep() {
      this.$router.push("/home");
    },
    async nextStep() {
      //if (this.resolution_selected) {
      // await this.$store.dispatch("getData", "step/sources");
      this.$router.push("/puckbutton");
      //}
    }
  }
};
</script>
<style scoped>
.img-titre-resolution {
  margin-top: -10px;
  width: 83px;
  height: 40px;
  background-image: url("/images/screen.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
