var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "container mt-5", attrs: { id: "informations" } },
      [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col", attrs: { align: "center" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row justify-content-center" },
              [
                _c("h1", { staticClass: "mb-3 mr-3" }, [
                  _vm._v("Informations")
                ]),
                _c(
                  "div",
                  [
                    _c("icon", {
                      attrs: {
                        name: "info-circle",
                        color: "#91295a",
                        scale: "3"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning uppercase",
                    attrs: { disabled: !_vm.$store.state.box_connected },
                    on: {
                      click: function($event) {
                        return _vm.startAnimation()
                      }
                    }
                  },
                  [_vm._v(" Terminer la configuration ")]
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "row d-flex flex-column align-items-center mt-3" },
              [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "alert alert-info" }, [
                    _c("h3", {}, [_vm._v("Votre Caméra")]),
                    _vm.camera && _vm.camera.plugged === "yes"
                      ? _c("div", [
                          _c("p", [_vm._v("Votre caméra est branchée.")]),
                          _vm.camera.on === "yes"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function($event) {
                                      return _vm.hideCamera()
                                    }
                                  }
                                },
                                [_vm._v("Arrêter la caméra sur l'écran")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function($event) {
                                      return _vm.showCamera()
                                    }
                                  }
                                },
                                [_vm._v("Tester la caméra sur l'écran")]
                              ),
                          _c("small", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Afficher la caméra du kit Emotivi sur la télévision afin de positionner au mieux la caméra."
                            )
                          ])
                        ])
                      : _vm.camera && _vm.camera.plugged === "no"
                      ? _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v("Votre caméra n'est pas branchée.")
                        ])
                      : _c("div", [
                          _c("div", {
                            staticClass: "spinner-border",
                            attrs: { role: "status" }
                          }),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Nous sommes en train de vérifier l'état de la caméra..."
                            )
                          ])
                        ])
                  ]),
                  _c("div", { staticClass: "alert alert-info" }, [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v("Informations Réseau")
                    ]),
                    _vm.network
                      ? _c("div", [
                          _c("p", { attrs: { id: "networkStateText" } }, [
                            _vm._v(
                              " Votre bande passante descendante est de " +
                                _vm._s(_vm.network.upload.bandwidth) +
                                " Mb/s."
                            ),
                            _c("br"),
                            _vm._v(
                              " Votre bande passante montante est de " +
                                _vm._s(_vm.network.download.bandwidth) +
                                " Mb/s. "
                            )
                          ]),
                          parseFloat(_vm.network.download.bandwidth) < 5 ||
                          parseFloat(_vm.network.upload.bandwidth) < 5
                            ? _c("div", { staticStyle: { color: "red" } }, [
                                _c("p", [
                                  _vm._v(
                                    "Votre connexion est très lente, nous ne garantissons pas la qualité du service."
                                  )
                                ])
                              ])
                            : parseFloat(_vm.network.download.bandwidth) < 10 ||
                              parseFloat(_vm.network.upload.bandwidth) < 10
                            ? _c("div", { staticStyle: { color: "#FE9A01" } }, [
                                _c("p", [
                                  _vm._v(
                                    "Votre connexion semble plutôt stable, mais quelques latances pourraient survenir pendant l'utilisation du service."
                                  )
                                ])
                              ])
                            : _c("div", { staticStyle: { color: "green" } }, [
                                _c("p", [
                                  _vm._v(
                                    "Votre connexion est excellente ! Vous ne rencontrerez aucun problème pendant l'utilisation de notre service."
                                  )
                                ])
                              ])
                        ])
                      : _c("div", [
                          _c("div", {
                            staticClass: "spinner-border",
                            attrs: { role: "status" }
                          }),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Nous sommes en train de tester votre connexion internet..."
                            )
                          ])
                        ])
                  ]),
                  _c("div", { staticClass: "alert alert-info" }, [
                    _c("h3", { staticClass: "mb-3" }, [_vm._v("Vos comptes")]),
                    _vm.informations
                      ? _c("div", { staticClass: "col-sm-12 col-md-5 " }, [
                          _c("h5", [_vm._v("Votre compte famille :")]),
                          _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                            _vm._v(
                              "Identifiant : " +
                                _vm._s(_vm.informations.en_webapp_login)
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "Mot de passe : " +
                                _vm._s(_vm.informations.en_webapp_password)
                            )
                          ]),
                          _vm.options.hasOwnProperty(
                            "options_display_connection_bar"
                          )
                            ? _c(
                                "div",
                                { staticClass: "alert alert-warning" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Souhaitez vous afficher les identifiants de connexion sur le téléviseur?"
                                    )
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.options
                                            .options_display_connection_bar,
                                        expression:
                                          "options.options_display_connection_bar"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options
                                          .options_display_connection_bar
                                      )
                                        ? _vm._i(
                                            _vm.options
                                              .options_display_connection_bar,
                                            null
                                          ) > -1
                                        : _vm.options
                                            .options_display_connection_bar
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options
                                                .options_display_connection_bar,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options,
                                                  "options_display_connection_bar",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options,
                                                  "options_display_connection_bar",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options,
                                              "options_display_connection_bar",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.saveOptions()
                                        }
                                      ]
                                    }
                                  }),
                                  _vm.options.options_display_connection_bar
                                    ? _c("span", [_vm._v("OUI")])
                                    : _c("span", [_vm._v("NON")])
                                ]
                              )
                            : _vm._e(),
                          _c("h5", [
                            _vm._v(
                              "Votre code administrateur pour emotivibox :"
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "Code administrateur : " +
                                _vm._s(_vm.informations.en_admincode)
                            )
                          ]),
                          _c("h5", [
                            _vm._v("Votre compte sur le site emotivi.fr :")
                          ]),
                          _c("p", [
                            _vm._v(
                              "Identifiant : " +
                                _vm._s(_vm.informations.co_login)
                            )
                          ]),
                          _c("h5", [
                            _vm._v(
                              "Etat kit Emotivi : " +
                                _vm._s(_vm.informations.status)
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "Version kit Emotivi : " +
                                _vm._s(_vm.informations.appemotivi_version) +
                                " (" +
                                _vm._s(_vm.informations.hardware_memory) +
                                ")"
                            )
                          ])
                        ])
                      : _c("div", { staticClass: "col-sm-12 col-md-5 mt-3" }, [
                          _c("div", {
                            staticClass: "spinner-border",
                            attrs: { role: "status" }
                          }),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Nous sommes en train de récupérer les informations de vos comptes Emotivi..."
                            )
                          ])
                        ])
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.previousStep()
                      }
                    }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { name: "arrow-left" }
                    }),
                    _vm._v(" Retour")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "col-8 d-flex flex-row justify-content-end" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning uppercase",
                      attrs: { disabled: !_vm.$store.state.box_connected },
                      on: {
                        click: function($event) {
                          return _vm.startAnimation()
                        }
                      }
                    },
                    [_vm._v(" Terminer la configuration ")]
                  )
                ]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }