<template>
  <div id="app">
    <!-- <div v-if="$store.state.mainloading && $route.name === 'home'" class="mainmodal d-flex justify-content-center align-items-center">
      <icon class="mr-2 fa-spin" name="sync-alt" color="white" scale="6"></icon>
    </div> -->
    <header style="height:5vh">
      <div v-if="false" class="container-nav2" :class="'bg-nav2-' + user.us_type">
        <ul class="nav d-flex justify-content-center">
          <li class="nav-item">
            <router-link
              to="/home"
              :class="{ 'nav-link': true, 'link-accueil': true, 'link-active': linkActive === 'home', disabled: !$store.state.connected }"
              style="border-left: 0 solid white"
              >Accueil</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/puckbutton"
              :class="{
                'nav-link': true,
                'link-accueil': true,
                'link-active': linkActive === 'puckbutton',
                disabled: !$store.state.connected || !$store.state.box_connected || !$store.state.start_config
              }"
              >Bouton</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/region"
              :class="{
                'nav-link': true,
                'link-active': linkActive === 'region',
                disabled: !$store.state.connected || !$store.state.box_connected || !$store.state.start_config
              }"
              >Région</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/sources"
              :class="{
                'nav-link': true,
                'link-active': linkActive === 'sources',
                disabled: !$store.state.connected || !$store.state.box_connected || !$store.state.start_config
              }"
              >Sources</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/informations"
              :class="{
                'nav-link': true,
                'link-active': linkActive === 'informations',
                disabled: !$store.state.connected || !$store.state.box_connected || !$store.state.start_config
              }"
              >Informations</router-link
            >
          </li>
          <li class="nav-item" style="cursor: pointer">
            <icon class="icon-menu" color="white" scale="2" name="sign-out-alt" @click="$router.push('/logout')"></icon>
          </li>
        </ul>
      </div>
      <div class="text-center mt-2 " v-if="linkActive !== 'home'" style="z-index:1000">
        <img style="width: 150px" class="img-fluid" src="/images/logo-emotivi.png" />
      </div>
      <div v-if="$store.state.connected" class="position-absolute" style="cursor: pointer; right: 1rem; top: 1rem; z-index:1000;">
        <icon class="icon-menu" color="#199aaa" scale="2" name="sign-out-alt" @click="$router.push('/logout')"></icon>
      </div>
      <div v-if="$store.state.connected && $route.name !== 'home'" class="position-absolute" style="cursor: pointer; left: 1rem; top: 1rem">
        <icon class="icon-menu" color="#199aaa" scale="2" name="home" @click="$router.push('/home')"></icon>
      </div>
    </header>
    <!-- <div class="row justify-content-center mt-3">
      <img
        style="max-width: 20%"
        class="img-fluid"
        src="/images/logo-emotivi.png"
        alt="logo Mouveal"
      />
    </div>-->
    <div style="overflow: auto;height:90vh">
      <!--  <div class="mb-4">
        <button class="btn btn-secondary ml-2" @click="$router.back()"><icon class="mr-2" name="arrow-left"></icon>Retour</button>
      </div> -->
      <router-view />
    </div>
    <!--
    <footer v-if="$route.name !== 'login'">
      <div class="container">
        <div class="row footer-logo">
          <div class="col-md-12">
            <img
              style="width:21%;"
              class="img-fluid"
              src="/images/logo-emotivi.png"
              alt="logo Mouveal"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <p>
              12 rue de la Corderie
              <br />13006 Marseille <br />Tel. 04 00 00 00 00 <br />Mail.
              contact@societé.com
              <br />
            </p>
          </div>
          <div class="col-md-3">
            <p class="footer-titre">Qui sommes-nous</p>
            <a>Charte de déontologie</a><br />
            <a>Politique deconfidentialité / RGPD</a><br />
            <a>Condition général</a>
          </div>
          <div class="col-md-3">
            <p class="footer-titre">Besoin d'aide</p>
            <a>Question fréquentes</a><br />
            <a>Professionnels de santé</a>
          </div>
          <div class="col-md-3">
            <p class="footer-titre">Contact</p>
            <a>Où nous trouver</a><br />
            <a>Prise de rendez-vous</a><br />
            <a>Nous contacter</a>
          </div>
        </div>
      </div>
    </footer>
    -->
    <footer class="flex-grow-0" style="height:5vh">
      <div class="d-flex flex-row align-items-center px-2 px-md-5 small">
        <!-- <img style="width: 7%" class="img-fluid mx-3" src="images/logo-emotivi.png" alt="logo Mouveal" /> -->
        <p>
          Design et développement : <span class="bis ml-2">La.team - {{ theCurrentYear }}</span> -
          <span class="font-weight-bold text-secondary">v{{ version }}</span>
        </p>
      </div>
    </footer>
    <m-message-dialog
      v-model="$store.state.dialogErr"
      title="Erreur"
      :text="$store.state.dialogErrTxt"
      @close="$store.dispatch('showDialogError', false)"
    ></m-message-dialog>
  </div>
</template>

<script>
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
// import "@fullcalendar/list/main.css";
// import Vue from "vue";
// import OfferDetails from "./views/OffersDetails";
// Vue.component("offer-details", OfferDetails);
import { version } from "../package.json";

export default {
  name: "App",
  components: {},
  data() {
    return {
      confirmLogoutDialog: false,
      drawer: true,
      nbClientSuivi: 0,
      nbBienSuivi: 0,
      //user: null,
      search: "",
      showMenu: false,
      disabledMenu: true,
      linkActive: this.$route.meta.menu,
      version,
      theCurrentYear: this.$moment().format("YYYY")
    };
  },
  watch: {
    $route(to, from) {
      // console.log("this.$route.name", this.$route.name, this.$route.meta.menu);
      // this.linkActive = this.$route.name;
      this.linkActive = this.$route.meta.menu;
      // if (
      //   this.$route.name === "rights" ||
      //   this.$route.name === "logs" ||
      //   this.$route.name === "lists" ||
      //   this.$route.name === "exports"
      // )
      //   this.linkActive = "preferences";
      if (this.$route.name !== "login") {
        this.showMenu = true;
        this.disabledMenu = false;
      } else {
        this.showMenu = false;
        this.disabledMenu = true;
      }
    },
    socket_response(response) {
      if (response.cmd == "stop-config") {
        this.$store.commit("set_start_config", false);
        this.$store.commit("set_mainloading", false);
      }
    }
  },
  sockets: {
    connect() {
      this.$store.dispatch("socketjoinroom", this.$socket);
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    socket_response() {
      return this.$store.state.socket_response;
    }
  },
  async mounted() {
    window.document.title = "Emotivi Configuration";
    //this.user = this.$store.state.user;
  },
  methods: {
    mytest() {
      alert("test");
    },
    // openWinContact(us_id) {
    //   this.$refs.contactwinedit.open(us_id);
    // },

    // resizeMainContainer() {
    //   if (!this.$refs.mainContainer) return;
    //   // if (this.$route.name == "login") {
    //   //   this.$refs.mainContainer.style.height = "100%";
    //   //   return;
    //   // }
    //   this.$refs.mainContainer.style.height =
    //     parseInt(window.innerHeight) - 64 + "px";
    // },
    go(where) {
      this.deleteResearch();
      this.$router.push(where);
    },
    deleteResearch() {
      this.search = "";
    },
    showMenus() {
      if (this.disabledMenu) return;
      this.showMenu = !this.showMenu;
    },
    // logout() {
    //   this.confirmLogoutDialog = false;
    //   this.$store.commit("set_connexion", {
    //     accesstoken: null,
    //     refreshtoken: null,
    //     user: null,
    //     preferences: null
    //   });
    //   this.$router.push("/login");
    // },
    // login() {
    //   this.$router.push("/login");
    // },
    saveWinUser() {
      // console.warn("this.$store.state.user.us_name", this.$store.state.user);
      // this.$refs.modal1.close();
    },
    openWinUser() {
      this.$refs.modal1.open();
    },
    closeWinUser() {
      this.$refs.modal1.close();
    }
  }
};
</script>
<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/styles.scss";
html {
  // background-color: #f4ece3;
  background-color: #fff;
}
h1 {
  color: #91295a;
}
#app {
  // background-color: #f4ece3;
  background-color: #fff;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.container-logo {
  display: none;
  padding: 15px;
}
.top-bar {
  background-color: #daece7;
  position: relative;
  overflow: hidden;
}
.bg-circle {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  top: -66px;
  left: -85px;
  height: 200px;
  width: 200px;
}
.collapse-menu {
  z-index: 10;
}

.user-name {
  margin-bottom: -5px;
  font-weight: 600;
}
.user-function {
  margin-bottom: 0px;
}
.avatar {
  background-color: #fff;
  border-radius: 50%;
  padding: 5px 5px;
  width: 50px;
  border: double 5px #e9e9e9;
  margin-top: 5px;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .container-logo {
    display: block;
  }
}
/************* nav2 ***************/
.container-nav2 {
  text-align: center;
  background-color: $color-blue;
  .nav-item {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .nav-link {
    color: #fff;
    text-transform: uppercase;
    border-left: solid 1px white;
    padding-top: 4px;
    padding-bottom: 5px;
  }
  .last-link {
    border-right: solid 1px white;
  }
  .nav-link:hover {
    background-color: #697882;
  }
  .nav-link.disabled {
    color: #cccccc;
  }
}
.link-active {
  background-color: $color-red;
}
/* .link-active {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
} */
/* @media (min-width: 768px) {
  .container-nav2 .nav-item {
    padding-top: 25px;
    padding-bottom: 25px;
  }
} */
/************ footer **************/

.footer-logo {
  padding-top: 90px;
  margin-bottom: 40px;
}
.footer-titre {
  color: #555e61;
  text-transform: uppercase;
}
.mainmodal {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
}
</style>
