var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container mt-5", attrs: { id: "puckbutton" } }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col", attrs: { align: "center" } }, [
          _c("div", { staticClass: "d-flex flex-row justify-content-center" }, [
            _c("h1", { staticClass: "mb-2 mr-3" }, [
              _vm._v("Configuration du bouton")
            ]),
            _c(
              "div",
              [
                _c("icon", {
                  attrs: { name: "circle", color: "#91295a", scale: "3" }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              ref: "buttonState",
              staticClass:
                "m-4 buttonState d-flex justify-content-center align-items-center"
            },
            [
              _vm.batterylevel > 0
                ? _c("p", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.batterylevel) + "%")
                  ])
                : _vm._e()
            ]
          ),
          _vm.buttonStatus == "appairing" ? _c("div", [_vm._m(0)]) : _vm._e(),
          _vm.light
            ? _c("div", [
                _c("div", { staticClass: "alert alert-info" }, [
                  _vm._v("Le bouton va s'allumer en vert.")
                ])
              ])
            : _vm._e(),
          _vm.batterylevel && _vm.batterylevel < 25
            ? _c("div", [_vm._m(1)])
            : _vm._e(),
          _vm.buttonStatus == "appaired" || _vm.buttonStatus == "notfound"
            ? _c("div", [
                _c("div", { staticClass: "alert alert-info" }, [
                  _vm.buttonStatus === "appaired" &&
                  _vm.buttonClick &&
                  _vm.buttonClick < 10
                    ? _c("div", [
                        _vm._v(
                          " Vous venez d'appuyer " +
                            _vm._s(_vm.buttonClick) +
                            " fois sur le bouton "
                        )
                      ])
                    : _vm._e(),
                  _vm.buttonStatus === "appaired" &&
                  _vm.buttonClick &&
                  _vm.buttonClick >= 10
                    ? _c("div", [
                        _vm._v(
                          " Vous venez d'activer le mode configuration sur le bouton "
                        )
                      ])
                    : _vm._e(),
                  _c("div", {}, [
                    _vm._v("Un bouton est associé à votre kit Emotivi.")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col d-flex flex-column" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger uppercase mt-3",
                          on: {
                            click: function($event) {
                              return _vm.unappair()
                            }
                          }
                        },
                        [_vm._v("Supprimer le bouton associé")]
                      ),
                      _c("small", { staticClass: "form-text text-muted" }, [
                        _vm._v(
                          "Cette action vous permet d'associer ensuite à votre kit Emotivi un nouveau bouton."
                        )
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm.buttonStatus == "notfound" ? _c("div", [_vm._m(2)]) : _vm._e(),
          _vm.buttonStatus == "notappaired"
            ? _c("div", [
                _c("div", { staticClass: "alert alert-info" }, [
                  _c("div", {}, [
                    _vm._v("Aucun bouton n'est associé à votre kit Emotivi.")
                  ]),
                  _vm.scannedButtons.length === 0 && _vm.displayScan
                    ? _c("div", { staticClass: "alert alert-danger mt-1" }, [
                        _vm._v(
                          ' Aucun bouton n\'a été trouvé. Démontez le bouton afin de vérifier que la pile est bien inséré, puis recliquez sur "Rechercher le bouton" '
                        )
                      ])
                    : _vm._e(),
                  _vm.scannedButtons.length === 1 &&
                  _vm.scannedButton &&
                  _vm.scannedButton.address &&
                  _vm.buttonConfigured
                    ? _c("div", { staticClass: "alert alert-danger mt-1" }, [
                        _vm._v(
                          " Un bouton déjà associé à un kit Emotivi a été scanné. Pour relancer l'association du bouton, réinitialisez le en retirant la pile et en la réinsérant tout en appuyant sur le bouton et en laissant appuyé jusqu'a ce que les leds ne s'allument plus. Enfin, appuyez sur Rechercher le bouton. "
                        )
                      ])
                    : _vm.scannedButtons.length === 1 &&
                      _vm.scannedButton &&
                      _vm.scannedButton.address &&
                      !_vm.buttonConfigured
                    ? _c("div", { staticClass: "mt-1" }, [
                        _c("b", [
                          _vm._v(
                            "Un bouton a été scanné. Cliquez sur 'ASSOCIER LE BOUTON'."
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.scannedButtons.length === 1 &&
                  _vm.scannedButton &&
                  _vm.scannedButton.address
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary uppercase w-100 mt-2",
                          attrs: { disabled: _vm.buttonConfigured },
                          on: {
                            click: function($event) {
                              return _vm.appair(_vm.scannedButton.address)
                            }
                          }
                        },
                        [_vm._v(" Associer le bouton ")]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm.buttonStatus !== "appaired" && _vm.buttonStatus !== "notfound"
            ? _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _vm.scanLoading
                    ? _c("p", [_vm._v("Recherche du bouton en cours...")])
                    : _vm._e(),
                  _vm.scanLoading
                    ? _c("icon", {
                        staticClass: "mr-2 mb-2 fa-spin",
                        attrs: { name: "sync-alt", color: "black", scale: "3" }
                      })
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary uppercase w-100",
                      attrs: { disabled: !_vm.showScanBt },
                      on: {
                        click: function($event) {
                          return _vm.scanButtons()
                        }
                      }
                    },
                    [_vm._v("Rechercher le bouton")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.scannedButtons.length > 1
            ? _c(
                "div",
                { staticClass: "row mt-4 justify-content-center" },
                [
                  _vm._l(_vm.scannedButtons, function(button) {
                    return _c(
                      "div",
                      {
                        key: button.address,
                        staticClass: "w-75",
                        attrs: { id: "scannedButtons" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row align-items-center justify-content-start w-100 p-1 position-relative"
                          },
                          [
                            _c("img", {
                              staticClass: "img-fluid mr-3",
                              staticStyle: { width: "15px" },
                              attrs: { src: "/images/button.png" }
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: button.rssi,
                                    expression: "button.rssi"
                                  }
                                ],
                                staticClass: "text-secondary mr-3"
                              },
                              [
                                _vm._v(
                                  "signal : " + _vm._s(button.rssi) + " db"
                                )
                              ]
                            ),
                            button.script
                              ? _c("span", { staticClass: "text-info mr-3" }, [
                                  _vm._v("Associé à un autre kit Emotivi")
                                ])
                              : _c("span", { staticClass: "text-info mr-3" }, [
                                  _vm._v("Non configuré")
                                ]),
                            _vm.buttonStatus === "appaired" &&
                            button.address === _vm.buttonMacAddress
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-light font-weight-bold flex-grow-1 d-flex align-items-center justify-content-center",
                                    staticStyle: {
                                      "background-color": "#91295a"
                                    }
                                  },
                                  [_vm._v(" Associé ")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex justify-content-end h-100",
                                staticStyle: { right: "0" }
                              },
                              [
                                _vm.buttonStatus !== "appaired" &&
                                !button.script
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary border d-flex align-items-center justify-content-center",
                                        on: {
                                          click: function($event) {
                                            return _vm.appair(button.address)
                                          }
                                        }
                                      },
                                      [_vm._v(" Associer ")]
                                    )
                                  : _vm._e(),
                                _vm.buttonStatus !== "appaired" &&
                                !button.script
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-warning border d-flex align-items-center justify-content-center",
                                        on: {
                                          click: function($event) {
                                            return _vm.testing(button.address)
                                          }
                                        }
                                      },
                                      [
                                        _vm.buttonsGreen.indexOf(
                                          button.address
                                        ) >= 0
                                          ? _c("span", [_vm._v("Eteindre")])
                                          : _c("span", [_vm._v("Allumer")])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  _vm.displayScan &&
                  _vm.buttonStatus === "appaired" &&
                  _vm.buttonMacAddress &&
                  !_vm.pairedButtonScanned
                    ? _c("p", { staticClass: "p-3" }, [
                        _vm._v(
                          " Le bouton associé à votre kit Emotivi n'a pas pu être scanné, il se peut que la pile soit déchargée ou que le bouton soit trop éloigné du kit. "
                        )
                      ])
                    : _vm._e(),
                  _vm.displayScan &&
                  _vm.buttonConfigured &&
                  _vm.buttonStatus === "notappaired"
                    ? _c("p", { staticClass: "p-3" }, [
                        _vm._v(
                          " Aucun bouton n'est associé à votre kit Emotivi mais un bouton déjà configuré a été scanné. Pour relancer l'association du bouton, réinitialisez le en retirant la pile et en la réinsérant tout en appuyant sur le bouton et en laissant appuyé jusqu'a ce que les leds ne s'allument plus. Enfin, appuyez sur associer un bouton. "
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-6 d-flex flex-row justify-content-left" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.previousStep()
                      }
                    }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { name: "arrow-left" }
                    }),
                    _vm._v(" Retour")
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "col-6 d-flex flex-row justify-content-end" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.nextStep()
                      }
                    }
                  },
                  [
                    _vm._v("Suivant "),
                    _c("icon", {
                      staticClass: "ml-2",
                      attrs: { name: "arrow-right" }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-info" }, [
      _c("b", [
        _vm._v("Votre bouton va s'allumer en rouge dans environ 10 secondes, "),
        _c("b", [_vm._v("appuyez sur le bouton pour confirmer l'association")]),
        _vm._v(". ")
      ]),
      _c("br"),
      _c("br"),
      _vm._v(
        " Si ce n'est pas le cas, veuillez procéder à sa réinitialisation, en enlevant la pile et en la remettant tout en laissant appuyer sur le bouton jusqu'a ce que les leds ne clignottent plus, enfin cliquez sur le bouton RECHERCHER LE BOUTON. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-warning" }, [
      _c("b", [
        _vm._v("Le niveau de charge de la pile de votre bouton est faible, "),
        _c("b", [_vm._v("Veuillez la remplacer rapidement")]),
        _vm._v(". ")
      ]),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-warning" }, [
      _c("div", {}, [
        _vm._v(
          " Un bouton est associé à votre kit Emotivi, mais n'a pas été trouvé. La pile est peut être déchargée, le bouton est hors de portée ou le processus d'association ne s'est pas terminé correctement. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }