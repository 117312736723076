var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "col m-1 p-2 d-flex flex-column justify-content-center align-items-center rounded",
      style: "cursor: pointer; background-color: " + _vm.bgcolor,
      on: {
        click: function($event) {
          return _vm.$router.push("/" + _vm.link)
        }
      }
    },
    [
      _vm.image
        ? _c("img", {
            staticClass: "image-icon mt-2",
            style: "width:" + _vm.scale + "rem; height:" + _vm.scale + "rem;",
            attrs: { src: _vm.image }
          })
        : _c("icon", {
            staticClass: "mt-2",
            attrs: { color: _vm.color, scale: _vm.scale, name: _vm.name }
          }),
      _c("span", { class: "text-" + _vm.color }, [_vm._v(_vm._s(_vm.label))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }