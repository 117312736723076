// import axios from "axios";
// import config from "../config";
// import store from "./store";
// import _ from "lodash";

const Utils = {
  randomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },

  randomstring(keep = "", length = 12) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (keep) result = keep + "__" + result;
    // console.log('result',result);
    return result;
  },

  async wait(funcToTest, maxTime) {
    return new Promise(function(resolve, reject) {
      let timeoutTest, timeoutMaxTime;
      function test() {
        timeoutTest = window.setTimeout(() => {
          if (funcToTest()) {
            if (timeoutMaxTime) window.clearTimeout(timeoutMaxTime);
            return resolve(true);
          }
          test();
        }, 10);
      }
      timeoutMaxTime = window.setTimeout(() => {
        if (timeoutTest) window.clearTimeout(timeoutTest);
        console.warn("timeoutmax");
        resolve(false);
      }, maxTime);
      test();
    });
  }
  // async doRequestIo(method, url, params) {
  //   try {
  //     let response = await axios[method](process.env.VUE_APP_BACKEND_URL + url, params ? params : null, {
  //       headers: {
  //         "x-auth-accesstoken": store.state.accesstoken,
  //         "Access-Control-Allow-Origin": "*"
  //       }
  //     });
  //     return response.data;
  //   } catch (e) {
  //     console.error("Error Ajax REquest - :", e);
  //     return null;
  //   }
  // }
};
export default Utils;
