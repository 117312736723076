<template>
  <section>
    <div class="container mt-5" id="camera">
      <div class="row align-items-center">
        <div class="col" align="center">
          <h1 class="text-center">Connexion</h1>
          <h5>Veuillez vous identifier à l'aide de votre <b>code administrateur</b></h5>
          <div v-if="errLogin" class="alert alert-danger">{{ errLogin }}</div>
          <m-form-text
            label="Code administrateur"
            v-model="en_admincode"
            label-position="top"
            name="login"
            style="text-align: start"
            autocomplete
          ></m-form-text>
          <button class="btn btn-primary mt-2" @click="login()">Se connecter</button>
          <small class="form-text text-muted">Le code administrateur vous a été envoyé dans votre email d'inscription.</small>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "login",
  components: {},
  async mounted() {
    if (this.$route.params.code_admin) {
      this.en_admincode = this.$route.params.code_admin;
      this.login();
    }
  },
  data() {
    return {
      en_admincode: "",
      errLogin: ""
    };
  },
  computed: {
    // startConfig() {
    //   return this.$store.state.start_config;
    // }
  },
  watch: {
    // startConfig(val) {
    //   if (val) this.$router.push("/home");
    // }
  },
  methods: {
    waitStartConfig() {
      return new Promise(resolve => {
        let waitLoop = 0;
        let wait = setInterval(() => {
          if (this.$store.state.start_config || waitLoop > 100) {
            clearInterval(wait);
            resolve();
          }
        }, 50);
      });
    },
    async login() {
      this.errLogin = "";
      try {
        let response = await this.$axios.post(this.$config.server_url + "/config/2.0/signin", {
          en_admincode: this.en_admincode
          //en_admincode ====> en_admincode pour la nouvelle BDD
        });

        if (response.data.err && response.data.err.key == "no_box_linked")
          return (this.errLogin = "Erreur de connexion : votre box n'a pas encore été installée.");
        else if (response.data.err) return (this.errLogin = "Erreur de connexion : vérifiez votre code et réessayez.");

        // console.log(response);
        this.$store.commit("set_connexion", {
          accesstoken: response.data.data.accesstoken,
          //refreshtoken: response.data.data.co_refreshtoken,
          user: response.data.data.row,
          more: response.data.data.more,
          preferences: response.data.data.preferences
        });
        // this.$store.dispatch("startConfig");
        console.warn("waiting start config event");
        // await this.waitStartConfig();
        this.$router.push("/home");
        // if (this.$route.params.code_admin) this.$router.push("/region");
        // else this.$router.push("/home");

        // console.log(this.$store.state.connected);
      } catch (error) {
        console.error("Erreur de co : ", error);
      }
    }
  }
};
</script>
