<template>
  <div class="container">
    <div class="row mt-5" align="center">
      <div class="col">
        <img style="width: 200px" class="img-fluid" src="/images/logo-emotivi.png" />
        <div>
          <!-- <img style="width: 70px" class="img-fluid" src="/images/home.png" /> -->
          <icon name="home" scale="3"></icon>
        </div>
        <div class="mt-3">
          <p>Bienvenue sur la page de configuration du kit Emotivi</p>
        </div>
        <div v-if="$store.state.box_connected" class="alert alert-info">
          <div>Vous êtes connecté à la box</div>
          <div>{{ allproblems }}</div>
          <div class="mt-3">
            <button
              v-if="!$store.state.start_config"
              class="btn btn-primary uppercase"
              @click="startConfig()"
              :disabled="!$store.state.box_connected"
            >
              Lancer la configuration sur la télévision
            </button>
            <small class="form-text text-muted" v-if="!$store.state.start_config"
              >Lancer la configuration, affichera les différentes pages de configuration sur la télévision. Il est conseillé d'être devant l'écran de
              télévision.</small
            >
            <!-- <button v-if="$store.state.start_config" class="btn btn-primary uppercase" @click="startButton()" :disabled="!$store.state.box_connected">
              Configurer votre kit Emotivi !!!
            </button> -->
            <div v-if="$store.state.start_config" class="row w-100 flex-wrap">
              <m-home-icon name="globe" link="region" label="Fuseau horaire" bgcolor="#189baa" :scale="3"></m-home-icon>
              <m-home-icon name="circle" link="puckbutton" label="Bouton" bgcolor="#189baa" :scale="3"></m-home-icon>
              <m-home-icon name="broadcast-tower" link="sources" label="Sources" bgcolor="#189baa" :scale="3"></m-home-icon>
              <m-home-icon name="info-circle" link="informations" label="Informations" bgcolor="#189baa" :scale="3"></m-home-icon>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-danger">Vous n'êtes pas connecté à la box, veuillez l'allumer et/ou la connecter à Internet</div>

        <button
          v-if="$store.state.start_config"
          class="btn btn-warning uppercase mt-3"
          @click="startAnimation()"
          :disabled="!$store.state.box_connected"
        >
          Terminer la configuration maintenant
        </button>

        <!-- <div v-if="lang == 'english'" class="">
          <img style="width: 50px" class="img-fluid mt-3" src="/images/uk-flag2.jpg" @click="$store.commit('set_lang', 'french')" />
        </div>
        <div v-else>
          <img style="width: 50px" class="img-fluid mt-3" src="/images/fr-flag2.png" @click="$store.commit('set_lang', 'english')" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  data() {
    return {
      isButtonClicked: false
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    // this.$store.dispatch("showDialogError", "Erreur les amis");
    // Wait 10sec for the variable this.$store.state.mysocket_connected_to_room set to true
    if (!(await this.waitSocketConnected())) return this.alertErrorAndReloadPage();
    this.$store.dispatch("getData", "current-part");
    this.$store.dispatch("getBoxState");
    this.$store.dispatch("sendTvPages", { key: "home", html: "", state: "", replace: "" });
  },
  watch: {
    socket_response(response) {
      console.warn("SOCKET SHOW OK 2", response.cmd, response.data);
      if (response.cmd === "start-config") {
        // console.log("data", data);
        if (!response.data) {
          this.alertErrorAndReloadPage();
          return;
        }
        this.$store.commit("set_start_config", true);
        this.$store.dispatch("sendTvPages", { key: "home", html: "", state: "", replace: "" });
        // this.$store.dispatch("getData", "informations");
        if (this.isButtonClicked) {
          this.isButtonClicked = false;
          return;
        }
        // this.$router.push("/region");
      }
      if (response.cmd === "start-animation") {
        this.$store.commit("set_start_config", false);
      }
    }
  },
  computed: {
    allproblems() {
      let prob = [];
      if (!this.$store.state.boxstates.leds) prob.push("L'avertisseur lumineux n'est pas branché");
      if (!this.$store.state.boxstates.camera) prob.push("La caméra n'est pas branchée");
      if (!this.$store.state.boxstates.button && this.$store.state.boxstates.button_paired) prob.push("Le bouton est introuvable");
      if (this.$store.state.boxstates.button_level_battery && this.$store.state.boxstates.button_level_battery < 25)
        prob.push("Le niveau de charge de la pile du bouton est faible");
      return prob.join(", ");
    },
    socket_response() {
      return this.$store.state.socket_response;
    },
    lang() {
      return this.$store.state.lang;
    }
  },
  methods: {
    startConfig() {
      this.isButtonClicked = true;
      this.$store.dispatch("setData", { cmd: "start-config" });
    },
    startAnimation() {
      this.$store.dispatch("setData", { cmd: "start-animation" });
    },
    startButton() {
      this.$router.push("/region");
    },
    waitSocketConnected() {
      return new Promise(resolve => {
        let waitLoop = 0;
        let wait = setInterval(() => {
          waitLoop++;
          if (this.$store.state.mysocket_connected_to_room || waitLoop > 100) {
            clearInterval(wait);
            resolve(this.$store.state.mysocket_connected_to_room ? true : false);
          }
        }, 100);
      });
    },
    alertErrorAndReloadPage() {
      alert("Il s'est produit une erreur. L'application va être rechargée.");
      document.location.reload();
    }
  }
  // computed: {
  //   // infos() {
  //   //   return this.$store.state.infos;
  //   // },
  // }
};
</script>
