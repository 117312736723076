<template>
  <div class="form-group">
    <label class="label" :class="labelClass" :for="id2" v-if="label">{{ label }}</label>
    <div class="input-group">
      <div class="input-group-prepend" v-if="prependText">
        <span class="input-group-text" v-html="prependText"></span>
      </div>
      <input
        v-if="type !== 'textarea'"
        ref="myinput"
        :aria-haspopup="ariaHaspopup"
        :aria-expanded="ariaExpanded"
        :type="type"
        :name="name"
        :class="{
          'bg-white': true,
          'form-control': true,
          'is-invalid': errormsg2
        }"
        :maxlength="maxlength"
        :id="id2"
        :disabled="disabled"
        aria-describedby
        :placeholder="placeholder"
        :value="value"
        :autocomplete="autocomplete"
        @click="onclick($event)"
        @input="oninput($event)"
        @focus="onfocus($event)"
        @blur="onblur($event)"
        @keyup="onkeyup($event)"
        @keydown="onkeydown($event)"
      />
      <textarea
        v-if="type === 'textarea'"
        ref="myinput"
        :aria-haspopup="ariaHaspopup"
        :aria-expanded="ariaExpanded"
        :name="name"
        :class="{
          textarea: true,
          'form-control': true,
          'is-invalid': errormsg2
        }"
        :id="id2"
        :disabled="disabled"
        aria-describedby
        :value="value"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        @click="onclick($event)"
        @input="oninput($event)"
        @focus="onfocus($event)"
        @blur="onblur($event)"
        @keyup="onkeyup($event)"
        @keydown="onkeydown($event)"
      >
      </textarea>
      <div class="input-group-prepend" v-if="appendText || appendIcon">
        <span v-if="appendText" class="input-group-text" v-html="appendText"></span>
        <span v-if="appendIcon" class="input-group-text">
          <icon class="icon-menu" :name="appendIcon"></icon>
        </span>
      </div>
      <div :id="id2" class="invalid-feedback" v-if="errormsg2">
        {{ errormsg2 }}
      </div>
    </div>
    <small class="sub-text form-text text-muted" v-if="subText" v-html="subText"></small>
  </div>
</template>

<script>
export default {
  name: "mformtext",
  props: {
    value: [String, Number],
    id: {
      default: "",
      type: String
    },
    type: {
      default: "text",
      type: String
    },
    name: {
      default: "",
      type: String
    },
    rows: {
      default: 4,
      type: Number
    },
    maxlength: {
      type: String
    },
    autocomplete: {
      default: "new-password",
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    autoSelectOnFocus: {
      default: true,
      type: Boolean
    },
    label: {
      default: "",
      type: String
    },
    tooltip: {
      default: "",
      type: String
    },
    tooltipPosition: {
      default: "bottom",
      type: String
    },
    ariaHaspopup: {
      default: false,
      type: Boolean
    },
    ariaExpanded: {
      default: false,
      type: Boolean
    },
    rules: {
      default: function() {
        return [];
      },
      type: Array
    },
    placeholder: String,
    prependIcon: String,
    appendIcon: String,
    prependText: String,
    appendText: String,
    subText: String
  },
  mounted() {
    if (this.id2) this.id2 = this.id;
    else this.id2 = this.$Utils.randomstring();
    // if (this.value2) this.id2 = this.id;
    // else this.id2 = this.$Utils.randomstring();
    window.addEventListener("click", this.onWindowClick);
  },
  destroyed() {
    window.removeEventListener("click", this.onWindowClick);
  },
  data() {
    return {
      id2: "",
      value2: "",
      errormsg2: "",
      focus: false
    };
  },
  watch: {
    value: function(val) {
      // if (val) this.focus = true;
      // this.value2 = val;
    }
  },
  computed: {
    labelClass() {
      let classTxt = "";
      if (this.type !== "textarea") {
        classTxt += this.focus || this.value ? "label_focus" : "";
      }
      if (this.type === "textarea") classTxt += " textarea_label";
      return classTxt;
    }
  },
  components: {},
  methods: {
    onWindowClick(evt) {
      // si je click sur le document
      if (!evt.target.name) {
        //this.showDropdown = false;
        // console.log("onWindowClick 1");
        this.$emit("ShowDropdownMenu", false);
      }
      let input = this.$store.getters.get_inputOpened;
      // si je click un imput
      if (input && input.name !== this.name) {
        //this.showDropdown = false;
        // console.log("onWindowClick 2");
        this.$emit("ShowDropdownMenu", false);
      }
    },
    informValid() {
      this.errormsg2 = "";
    },
    informInvalid(txt) {
      this.errormsg2 = txt;
    },
    validate() {
      let kos = [];
      for (let iRule = 0; iRule < this.rules.length; iRule++) {
        const rule = this.rules[iRule];
        let ok = rule(this.value2);
        if (this.$_.isString(ok)) kos.push(ok);
      }
      if (kos.length == 0) {
        this.informValid();
        return true;
      }
      this.informInvalid(kos.join(", "));
      return false;
    },
    onkeyup(evt) {
      this.$emit("keyup", evt);
    },
    onkeydown(evt) {
      this.$emit("keydown", evt);
    },
    oninput(evt) {
      let v = evt.target.value;
      if (this.type == "number" && v) {
        if ((v + "").indexOf(",")) v = (v + "").replace(/\,/, ".");
        v = v * 1;
      }
      this.value2 = v;
      // console.log("this.value2", this.value2);
      this.validate();
      this.$emit("input", this.value2, evt);
    },
    onclick(evt) {
      this.$emit("ShowDropdownMenu", true);
      let input = this.$store.getters.get_inputOpened;
      if (!input || (input && input.name !== evt.target.name)) {
        this.$store.commit("set_inputOpened", this);
      }
      this.$emit("click", evt);
    },
    onfocus(evt) {
      if (this.type !== "textarea") this.focus = true;
      this.$emit("ShowDropdownMenu", true);
      if (this.autoSelectOnFocus) evt.target.select();
      let input = this.$store.getters.get_inputOpened;
      if (!input || (input && input.name !== evt.target.name)) {
        this.$store.commit("set_inputOpened", this);
      }
      this.$emit("focus", evt);
    },
    onblur() {
      if (!this.value2 || !this.value) {
        this.focus = false;
      }
    },
    hideDropdown() {
      // console.log("hideDropdown");
      this.$emit("ShowDropdownMenu", false);
    }
  }
};
</script>

<style lang="scss">
.form-control {
  // border-radius: 0;
  // border-radius: 0;
  //border: 0px;
  //border-bottom: 1px solid #999999;
  background-color: #ffffff00;
  height: 30px;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  margin-bottom: 10px;
}
.bg-white {
  background-color: #ffffff;
}
.textarea {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
}
.form-group {
  position: relative;
  margin: 15px 0 0 0;
}
/* .input-group > .form-control {
  height: 34px;
} */
.label {
  margin-bottom: 0px;
  /* position: absolute;
  top: 0;
  transition: all 0.3s ease; */
}
.label_focus {
  /*  top: -20px;
  font-size: 12px;
  color: gray; */
}
.textarea_label {
  position: relative;
}
.m-form-base {
  .m-form-base__container-input {
    textarea {
      border-radius: 5px;
      border: 1px solid gray;
    }
  }
}
// .sub-text {
//   color: #4950579c;
// }
</style>
