import { render, staticRenderFns } from "./MList.vue?vue&type=template&id=63f8c52b&"
import script from "./MList.vue?vue&type=script&lang=js&"
export * from "./MList.vue?vue&type=script&lang=js&"
import style0 from "./MList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/emotivi-v2-config/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63f8c52b')) {
      api.createRecord('63f8c52b', component.options)
    } else {
      api.reload('63f8c52b', component.options)
    }
    module.hot.accept("./MList.vue?vue&type=template&id=63f8c52b&", function () {
      api.rerender('63f8c52b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MList.vue"
export default component.exports