var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container mt-5", attrs: { id: "camera" } }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c(
          "div",
          { staticClass: "col", attrs: { align: "center" } },
          [
            _c("h1", { staticClass: "text-center" }, [_vm._v("Connexion")]),
            _vm._m(0),
            _vm.errLogin
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.errLogin))
                ])
              : _vm._e(),
            _c("m-form-text", {
              staticStyle: { "text-align": "start" },
              attrs: {
                label: "Code administrateur",
                "label-position": "top",
                name: "login",
                autocomplete: ""
              },
              model: {
                value: _vm.en_admincode,
                callback: function($$v) {
                  _vm.en_admincode = $$v
                },
                expression: "en_admincode"
              }
            }),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-2",
                on: {
                  click: function($event) {
                    return _vm.login()
                  }
                }
              },
              [_vm._v("Se connecter")]
            ),
            _c("small", { staticClass: "form-text text-muted" }, [
              _vm._v(
                "Le code administrateur vous a été envoyé dans votre email d'inscription."
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _vm._v("Veuillez vous identifier à l'aide de votre "),
      _c("b", [_vm._v("code administrateur")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }