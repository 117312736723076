var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container mt-5", attrs: { id: "resolution" } }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col", attrs: { align: "center" } }, [
          _c("div", { staticClass: "d-flex flex-row justify-content-center" }, [
            _c("h1", { staticClass: "mb-5 mr-3" }, [
              _vm._v("Configuration régionale")
            ]),
            _c(
              "div",
              [
                _c("icon", {
                  attrs: { name: "globe", color: "#91295a", scale: "3" }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "alert alert-info" }, [
            _c("div", { staticClass: "form-group mt-0" }, [
              _c("label", { attrs: { for: "resolutionBox" } }, [
                _vm._v("Choix de votre langue")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.region.lang,
                      expression: "region.lang"
                    }
                  ],
                  staticClass: "custom-select",
                  attrs: { id: "resolutionBox", name: "resolutionBox" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.region,
                          "lang",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.saveData()
                      }
                    ]
                  }
                },
                _vm._l(_vm.langs, function(lang) {
                  return _c(
                    "option",
                    {
                      key: lang.key,
                      attrs: { selected: "" },
                      domProps: { value: lang.key }
                    },
                    [_vm._v(_vm._s(lang.name))]
                  )
                }),
                0
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "Emotivi gérera très prochainement l'anglais, l'allemand, l'italien, l'espagnol, le portugais."
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "alert alert-info" }, [
            _c("div", { staticClass: "form-group mt-0" }, [
              _c("label", { attrs: { for: "resolutionBox" } }, [
                _vm._v("Fuseau horaire")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.region.timezone,
                      expression: "region.timezone"
                    }
                  ],
                  staticClass: "custom-select",
                  attrs: { id: "resolutionBox", name: "resolutionBox" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.region,
                          "timezone",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.saveData()
                      }
                    ]
                  }
                },
                _vm._l(_vm.timezones, function(zone) {
                  return _c(
                    "option",
                    { key: zone.key, domProps: { value: zone.key } },
                    [
                      _vm._v(
                        _vm._s(zone.name_fr) + " (" + _vm._s(zone.offset) + ")"
                      )
                    ]
                  )
                }),
                0
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "Emotivi gérera très prochainement l'anglais, l'allemand, l'italien, l'espagnol, le portugais."
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c(
              "div",
              { staticClass: "col-6 d-flex flex-row justify-content-left" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.previousStep()
                      }
                    }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { name: "arrow-left" }
                    }),
                    _vm._v(" Retour")
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "col-6 d-flex flex-row justify-content-end" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.nextStep()
                      }
                    }
                  },
                  [
                    _vm._v("Suivant "),
                    _c("icon", {
                      staticClass: "ml-2",
                      attrs: { name: "arrow-right" }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c("div", { staticClass: "mt-5" })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }