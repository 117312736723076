var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container mt-5", attrs: { id: "sources" } }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c(
          "div",
          { staticClass: "col", attrs: { align: "center" } },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-row justify-content-center" },
              [
                _c("h1", { staticClass: "mb-3 mr-3" }, [
                  _vm._v("Configuration des sources")
                ]),
                _c(
                  "div",
                  [
                    _c("icon", {
                      attrs: {
                        name: "broadcast-tower",
                        color: "#91295a",
                        scale: "3"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "form-group alert alert-info" }, [
              _c("label", { attrs: { for: "sourceBox" } }, [
                _vm._v("Port HDMI du kit Emotivi :")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sources.source_emotivi,
                      expression: "sources.source_emotivi"
                    }
                  ],
                  staticClass: "custom-select",
                  attrs: { id: "sourceBox", name: "sourceBox" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.sources,
                          "source_emotivi",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.saveData()
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Sélectionnez un numéro.")
                  ]),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("Aucun")]),
                  _vm._l(_vm.sourceListEmotivi, function(source) {
                    return _c(
                      "option",
                      { key: source.key, domProps: { value: source.key } },
                      [_vm._v(_vm._s(source.name))]
                    )
                  })
                ],
                2
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "Sélectionner le numéro de la prise HDMI de votre télévision sur laquelle le kit Emotivi est branché (sur la télévision et non pas sur le kit)."
                )
              ])
            ]),
            _c("div", { staticClass: "form-group alert alert-info" }, [
              _c("label", { attrs: { for: "sourceTv" } }, [
                _vm._v("Sources des chaînes TV")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sources.source_tv,
                      expression: "sources.source_tv"
                    }
                  ],
                  staticClass: "custom-select",
                  attrs: { id: "sourceTv", name: "sourceTv" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.sources,
                          "source_tv",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.saveData()
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Sélectionnez une source.")
                  ]),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("Aucun")]),
                  _vm._l(_vm.sourceListTv, function(source) {
                    return _c(
                      "option",
                      { key: source.key, domProps: { value: source.key } },
                      [_vm._v(_vm._s(source.name))]
                    )
                  })
                ],
                2
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "Sélectionner la source de votre télévision ; pour connaitre la source, grace à votre télécommande, mettez la télévision et observer la source (généralement TV ou HDMI2)"
                )
              ])
            ]),
            _c("div", { staticClass: "form-group alert alert-info" }, [
              _c("label", [_vm._v("Choissisez votre modèle de télévision")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sources.source_tvbrand,
                      expression: "sources.source_tvbrand"
                    }
                  ],
                  staticClass: "custom-select",
                  attrs: { id: "sourceTV", name: "sourceTV" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.sources,
                          "source_tvbrand",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.saveData("tvbrand")
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Sélectionnez un modèle de télévision.")
                  ]),
                  _c("option", { attrs: { value: "other" } }, [
                    _vm._v("Autre modèle")
                  ]),
                  _vm._l(_vm.tvbrands, function(tvbrand) {
                    return _c(
                      "option",
                      {
                        key: tvbrand.tt_key,
                        domProps: { value: tvbrand.tt_key }
                      },
                      [_vm._v(_vm._s(tvbrand.tt_name))]
                    )
                  })
                ],
                2
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  " Dans certain cas, nous avons besoin d'utiliser l'infra-rouge (comme votre télécommande) afin de changer la source de votre télévision. Pour cela, nous avons besoin de connaitre le modèle de votre télévision. "
                )
              ])
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-3",
                on: {
                  click: function($event) {
                    return _vm.testing("totv")
                  }
                }
              },
              [_vm._v("Tester le changement de source Emotivi -> TV")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-2",
                on: {
                  click: function($event) {
                    return _vm.testing("toemotivi")
                  }
                }
              },
              [_vm._v("Tester le changement de source TV -> EmotiviBox")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-2",
                on: {
                  click: function($event) {
                    return _vm.testing("switchoff")
                  }
                }
              },
              [_vm._v("Tester l'extinction de la télévision")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-2",
                on: {
                  click: function($event) {
                    return _vm.testing("switchon")
                  }
                }
              },
              [_vm._v("Tester l'allumage de la télévision")]
            ),
            _c("div", { staticClass: "mt-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary2 w-100",
                  on: {
                    click: function($event) {
                      _vm.showMoreOptions = !_vm.showMoreOptions
                    }
                  }
                },
                [
                  _vm._v(" Options avançées et aide "),
                  _c("icon", {
                    staticClass: "ml-2",
                    attrs: { name: "chevron-down" }
                  })
                ],
                1
              )
            ]),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showMoreOptions
                ? _c("div", { staticClass: "text-left" }, [
                    _c("h5", { staticClass: "mt-3" }, [
                      _vm._v("Source Emotivi")
                    ]),
                    _vm.sources.source_emotivi_use_cec &&
                    _vm.sources.source_emotivi_use_ir
                      ? _c("p", { staticClass: "alert alert-warning" }, [
                          _vm._v(
                            " Attention vous avez sélectionné le CEC et l'infrarouge pour le changement de source vers Emotivi, il se pourrait que le téléviseur ait un comportement inattendu. "
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_emotivi_use_cec,
                            expression: "sources.source_emotivi_use_cec"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          id: "checkboxEmotiviUseCec",
                          checked: ""
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.sources.source_emotivi_use_cec
                          )
                            ? _vm._i(_vm.sources.source_emotivi_use_cec, null) >
                              -1
                            : _vm.sources.source_emotivi_use_cec
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.sources.source_emotivi_use_cec,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_emotivi_use_cec",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_emotivi_use_cec",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.sources,
                                  "source_emotivi_use_cec",
                                  $$c
                                )
                              }
                            },
                            function($event) {
                              return _vm.saveData()
                            }
                          ]
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkboxEmotiviUseCec" }
                        },
                        [
                          _vm._v(
                            "Utiliser la prise HDMI (CEC) pour TV -> Emotivi"
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_emotivi_use_ir,
                            expression: "sources.source_emotivi_use_ir"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          id: "checkboxEmotiviUseIr",
                          checked: ""
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.sources.source_emotivi_use_ir
                          )
                            ? _vm._i(_vm.sources.source_emotivi_use_ir, null) >
                              -1
                            : _vm.sources.source_emotivi_use_ir
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.sources.source_emotivi_use_ir,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_emotivi_use_ir",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_emotivi_use_ir",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.sources,
                                  "source_emotivi_use_ir",
                                  $$c
                                )
                              }
                            },
                            function($event) {
                              return _vm.saveData()
                            }
                          ]
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkboxEmotiviUseIr" }
                        },
                        [_vm._v("Utiliser l'infrarouge pour TV -> Emotivi")]
                      )
                    ]),
                    _vm.sources.source_emotivi_macro !== undefined
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-group alert alert-secondary border"
                          },
                          [
                            _c("label", [
                              _vm._v(
                                "Choisissez une macro infrarouge si nécessaire"
                              )
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sources.source_emotivi_macro,
                                    expression: "sources.source_emotivi_macro"
                                  }
                                ],
                                staticClass: "custom-select",
                                attrs: {
                                  id: "sourceEmotiviMacro",
                                  name: "sourceEmotiviMacro"
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.sources,
                                        "source_emotivi_macro",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function($event) {
                                      return _vm.saveData()
                                    }
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Aucune")
                                ]),
                                _vm._l(_vm.tvBrandMacrosToEmotivi, function(
                                  macro
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: macro.ma_key,
                                      domProps: { value: macro.ma_key }
                                    },
                                    [_vm._v(_vm._s(macro.ma_label))]
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("h5", { staticClass: "mt-3" }, [
                      _vm._v("Source des chaînes TV")
                    ]),
                    _vm.sources.source_tv_use_cec &&
                    _vm.sources.source_tv_use_ir
                      ? _c("p", { staticClass: "alert alert-warning" }, [
                          _vm._v(
                            " Attention vous avez sélectionné le CEC et l'infrarouge pour le changement de source vers les chaînes TV, il se pourrait que le téléviseur ait un comportement inattendu. "
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_tv_use_cec,
                            expression: "sources.source_tv_use_cec"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          id: "checkboxTvUseCec",
                          checked: ""
                        },
                        domProps: {
                          checked: Array.isArray(_vm.sources.source_tv_use_cec)
                            ? _vm._i(_vm.sources.source_tv_use_cec, null) > -1
                            : _vm.sources.source_tv_use_cec
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.sources.source_tv_use_cec,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_tv_use_cec",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_tv_use_cec",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.sources, "source_tv_use_cec", $$c)
                              }
                            },
                            function($event) {
                              return _vm.saveData()
                            }
                          ]
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkboxTvUseCec" }
                        },
                        [
                          _vm._v(
                            "Utiliser la prise HDMI (CEC) pour Emotivi -> TV"
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_tv_use_ir,
                            expression: "sources.source_tv_use_ir"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          id: "checkboxTvUseIr",
                          checked: ""
                        },
                        domProps: {
                          checked: Array.isArray(_vm.sources.source_tv_use_ir)
                            ? _vm._i(_vm.sources.source_tv_use_ir, null) > -1
                            : _vm.sources.source_tv_use_ir
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.sources.source_tv_use_ir,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_tv_use_ir",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_tv_use_ir",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.sources, "source_tv_use_ir", $$c)
                              }
                            },
                            function($event) {
                              return _vm.saveData()
                            }
                          ]
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkboxTvUseIr" }
                        },
                        [_vm._v("Utiliser l'infrarouge pour Emotivi -> TV")]
                      )
                    ]),
                    _vm.sources.source_tv_macro !== undefined
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-group alert alert-secondary border"
                          },
                          [
                            _c("label", [
                              _vm._v(
                                "Choisissez une macro infrarouge si nécessaire"
                              )
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sources.source_tv_macro,
                                    expression: "sources.source_tv_macro"
                                  }
                                ],
                                staticClass: "custom-select",
                                attrs: {
                                  id: "sourceTVMacro",
                                  name: "sourceTVMacro"
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.sources,
                                        "source_tv_macro",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function($event) {
                                      return _vm.saveData()
                                    }
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Aucune")
                                ]),
                                _vm._l(_vm.tvBrandMacrosToTv, function(macro) {
                                  return _c(
                                    "option",
                                    {
                                      key: macro.ma_key,
                                      domProps: { value: macro.ma_key }
                                    },
                                    [_vm._v(_vm._s(macro.ma_label))]
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.sources.source_switch_on_use_cec !== undefined
                      ? _c("div", [
                          _c("h5", { staticClass: "mt-3" }, [
                            _vm._v("Allumage de la TV")
                          ]),
                          _vm.sources.source_switch_on_use_cec &&
                          _vm.sources.source_switch_on_use_ir
                            ? _c("p", { staticClass: "alert alert-warning" }, [
                                _vm._v(
                                  " Attention vous avez sélectionné le CEC et l'infrarouge pour l'allumage, il se pourrait que le téléviseur ait un comportement inattendu. "
                                )
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "form-check" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sources.source_switch_on_use_cec,
                                  expression: "sources.source_switch_on_use_cec"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id: "checkboxSwitchOnUseCec",
                                checked: ""
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sources.source_switch_on_use_cec
                                )
                                  ? _vm._i(
                                      _vm.sources.source_switch_on_use_cec,
                                      null
                                    ) > -1
                                  : _vm.sources.source_switch_on_use_cec
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a =
                                        _vm.sources.source_switch_on_use_cec,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_on_use_cec",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_on_use_cec",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.sources,
                                        "source_switch_on_use_cec",
                                        $$c
                                      )
                                    }
                                  },
                                  function($event) {
                                    return _vm.saveData()
                                  }
                                ]
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "checkboxSwitchOnUseCec" }
                              },
                              [
                                _vm._v(
                                  "Utiliser la prise HDMI (CEC) pour l'allumage de la TV"
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "form-check" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sources.source_switch_on_use_ir,
                                  expression: "sources.source_switch_on_use_ir"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id: "checkboxSwitchOnUseIr"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sources.source_switch_on_use_ir
                                )
                                  ? _vm._i(
                                      _vm.sources.source_switch_on_use_ir,
                                      null
                                    ) > -1
                                  : _vm.sources.source_switch_on_use_ir
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a =
                                        _vm.sources.source_switch_on_use_ir,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_on_use_ir",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_on_use_ir",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.sources,
                                        "source_switch_on_use_ir",
                                        $$c
                                      )
                                    }
                                  },
                                  function($event) {
                                    return _vm.saveData()
                                  }
                                ]
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "checkboxSwitchOnUseIr" }
                              },
                              [
                                _vm._v(
                                  "Utiliser l'infrarouge pour l'allumage de la TV"
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.sources.source_switch_off_use_cec !== undefined
                      ? _c("div", [
                          _c("h5", { staticClass: "mt-3" }, [
                            _vm._v("Extinction de la TV")
                          ]),
                          _vm.sources.source_switch_off_use_cec &&
                          _vm.sources.source_switch_off_use_ir
                            ? _c("p", { staticClass: "alert alert-warning" }, [
                                _vm._v(
                                  " Attention vous avez sélectionné le CEC et l'infrarouge pour l'extinction, il se pourrait que le téléviseur ait un comportement inattendu. "
                                )
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "form-check" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sources.source_switch_off_use_cec,
                                  expression:
                                    "sources.source_switch_off_use_cec"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id: "checkboxSwitchOffUseCec",
                                checked: ""
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sources.source_switch_off_use_cec
                                )
                                  ? _vm._i(
                                      _vm.sources.source_switch_off_use_cec,
                                      null
                                    ) > -1
                                  : _vm.sources.source_switch_off_use_cec
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a =
                                        _vm.sources.source_switch_off_use_cec,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_off_use_cec",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_off_use_cec",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.sources,
                                        "source_switch_off_use_cec",
                                        $$c
                                      )
                                    }
                                  },
                                  function($event) {
                                    return _vm.saveData()
                                  }
                                ]
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "checkboxSwitchOnUseCec" }
                              },
                              [
                                _vm._v(
                                  "Utiliser la prise HDMI (CEC) pour l'extinction de la TV"
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "form-check" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sources.source_switch_off_use_ir,
                                  expression: "sources.source_switch_off_use_ir"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id: "checkboxSwitchOffUseIr"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sources.source_switch_off_use_ir
                                )
                                  ? _vm._i(
                                      _vm.sources.source_switch_off_use_ir,
                                      null
                                    ) > -1
                                  : _vm.sources.source_switch_off_use_ir
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a =
                                        _vm.sources.source_switch_off_use_ir,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_off_use_ir",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.sources,
                                            "source_switch_off_use_ir",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.sources,
                                        "source_switch_off_use_ir",
                                        $$c
                                      )
                                    }
                                  },
                                  function($event) {
                                    return _vm.saveData()
                                  }
                                ]
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "checkboxSwitchOnUseIr" }
                              },
                              [
                                _vm._v(
                                  "Utiliser l'infrarouge pour l'extinction de la TV"
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.sources.source_cec_delay !== undefined
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "cecDelay" } }, [
                            _vm._v(
                              "Délai entre l'envoi des intructions HDMI (CEC) :"
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sources.source_cec_delay,
                                expression: "sources.source_cec_delay"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number", id: "cecDelay" },
                            domProps: { value: _vm.sources.source_cec_delay },
                            on: {
                              change: function($event) {
                                return _vm.saveData()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sources,
                                  "source_cec_delay",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "tvUseCec" } }, [
                        _vm._v("Durée de l'envoi des intructions infrarouge : ")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_ir_duration,
                            expression: "sources.source_ir_duration"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", id: "tvUseCec" },
                        domProps: { value: _vm.sources.source_ir_duration },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_ir_duration",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_ir_repeat,
                            expression: "sources.source_ir_repeat"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", id: "checkboxIrImpulse" },
                        domProps: {
                          checked: Array.isArray(_vm.sources.source_ir_repeat)
                            ? _vm._i(_vm.sources.source_ir_repeat, null) > -1
                            : _vm.sources.source_ir_repeat
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.sources.source_ir_repeat,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_ir_repeat",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_ir_repeat",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.sources, "source_ir_repeat", $$c)
                              }
                            },
                            function($event) {
                              return _vm.saveData()
                            }
                          ]
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkboxIrImpulse" }
                        },
                        [
                          _vm._v(
                            "Envoyer plusieurs fois l'instruction infrarouge"
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "irDelay" } }, [
                        _vm._v(
                          "Délai entre l'envoi des intructions infrarouge :"
                        )
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_ir_delay,
                            expression: "sources.source_ir_delay"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", id: "irDelay" },
                        domProps: { value: _vm.sources.source_ir_delay },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_ir_delay",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_ignorecec,
                            expression: "sources.source_ignorecec"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", id: "checkboxIgnorecec" },
                        domProps: {
                          checked: Array.isArray(_vm.sources.source_ignorecec)
                            ? _vm._i(_vm.sources.source_ignorecec, null) > -1
                            : _vm.sources.source_ignorecec
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.sources.source_ignorecec,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_ignorecec",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.sources,
                                      "source_ignorecec",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.sources, "source_ignorecec", $$c)
                              }
                            },
                            function($event) {
                              return _vm.saveData()
                            }
                          ]
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkboxIgnorecec" }
                        },
                        [
                          _vm._v(
                            "Ignorer la vérification de l'état de la télévision lors d'un appel."
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "text-align-left mt-4" }, [
                      _c("h5", [_vm._v("Nom du CEC par marques")]),
                      _c("p", [
                        _vm._v(
                          " Vous devez peut-être activer les options CEC dans votre téléviseurs. Aidez-vous de cette liste pour activer le CEC sur votre télévision "
                        )
                      ]),
                      _c("ul", [
                        _c("li", [_vm._v("AOC: E-link")]),
                        _c("li", [_vm._v("Hitachi: HDMI-CEC")]),
                        _c("li", [_vm._v("Insignia: (INlink)")]),
                        _c("li", [_vm._v("ITT: T-Link")]),
                        _c("li", [_vm._v("LG: SimpLink")]),
                        _c("li", [_vm._v("Mitsubishi: NetCommand for HDMI")]),
                        _c("li", [
                          _vm._v("Onkyo: RIHD (Remote Interactive over HDMI)")
                        ]),
                        _c("li", [
                          _vm._v(
                            "Panasonic: HDAVI Control, EZ-Sync, or VIERA Link"
                          )
                        ]),
                        _c("li", [_vm._v("Philips: EasyLink")]),
                        _c("li", [_vm._v("Pioneer: Kuro Link")]),
                        _c("li", [_vm._v("Runco International: RuncoLink")]),
                        _c("li", [_vm._v("Samsung: Anynet+")]),
                        _c("li", [_vm._v("Sharp: Aquos Link")]),
                        _c("li", [_vm._v("Sony: BRAVIA Sync and BRAVIA Sync")]),
                        _c("li", [_vm._v("Toshiba: CE-Link and Regza Link")]),
                        _c("li", [_vm._v("Vizio: CEC")])
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-6",
                          attrs: { for: "source_pronto_switch_on" }
                        },
                        [_vm._v("Pronto allumer :")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_pronto_switch_on,
                            expression: "sources.source_pronto_switch_on"
                          }
                        ],
                        staticClass: "col form-control",
                        attrs: { type: "text", id: "source_pronto_switch_on" },
                        domProps: {
                          value: _vm.sources.source_pronto_switch_on
                        },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_pronto_switch_on",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-6",
                          attrs: { for: "source_pronto_switch_off" }
                        },
                        [_vm._v("Pronto eteindre :")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_pronto_switch_off,
                            expression: "sources.source_pronto_switch_off"
                          }
                        ],
                        staticClass: "col form-control",
                        attrs: { type: "text", id: "source_pronto_switch_off" },
                        domProps: {
                          value: _vm.sources.source_pronto_switch_off
                        },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_pronto_switch_off",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-6",
                          attrs: { for: "source_pronto_emotivi" }
                        },
                        [_vm._v("Pronto vers Emotivi :")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_pronto_emotivi,
                            expression: "sources.source_pronto_emotivi"
                          }
                        ],
                        staticClass: "col form-control",
                        attrs: { type: "text", id: "source_pronto_emotivi" },
                        domProps: { value: _vm.sources.source_pronto_emotivi },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_pronto_emotivi",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-6",
                          attrs: { for: "source_pronto_tv" }
                        },
                        [_vm._v("Pronto vers TV :")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_pronto_tv,
                            expression: "sources.source_pronto_tv"
                          }
                        ],
                        staticClass: "col form-control",
                        attrs: { type: "text", id: "source_pronto_tv" },
                        domProps: { value: _vm.sources.source_pronto_tv },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_pronto_tv",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-6",
                          attrs: { for: "source_pronto_sound_plus" }
                        },
                        [_vm._v("Pronto son UP :")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_pronto_sound_plus,
                            expression: "sources.source_pronto_sound_plus"
                          }
                        ],
                        staticClass: "col form-control",
                        attrs: { type: "text", id: "source_pronto_sound_plus" },
                        domProps: {
                          value: _vm.sources.source_pronto_sound_plus
                        },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_pronto_sound_plus",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-6",
                          attrs: { for: "source_pronto_sound_minus" }
                        },
                        [_vm._v("Pronto son DOWN :")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sources.source_pronto_sound_minus,
                            expression: "sources.source_pronto_sound_minus"
                          }
                        ],
                        staticClass: "col form-control",
                        attrs: {
                          type: "text",
                          id: "source_pronto_sound_minus"
                        },
                        domProps: {
                          value: _vm.sources.source_pronto_sound_minus
                        },
                        on: {
                          change: function($event) {
                            return _vm.saveData()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sources,
                              "source_pronto_sound_minus",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-6 d-flex flex-row justify-content-left" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      on: {
                        click: function($event) {
                          return _vm.previousStep()
                        }
                      }
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-2",
                        attrs: { name: "arrow-left" }
                      }),
                      _vm._v(" Retour")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "col-6 d-flex flex-row justify-content-end" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      on: {
                        click: function($event) {
                          return _vm.nextStep()
                        }
                      }
                    },
                    [
                      _vm._v("Suivant "),
                      _c("icon", {
                        staticClass: "ml-2",
                        attrs: { name: "arrow-right" }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }